import React, { useState } from "react";
import { Layout, Menu } from "antd";
import {
  PieChartOutlined,
  FileImageOutlined,
  VideoCameraOutlined,
  PictureOutlined,
  ReadOutlined,
  AppstoreOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";

// import GalleryImg from '../../../assets/images/icon/gallery.png';
// import BlogImg from '../../../assets/images/icon/blogs.png';

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (collaps: any) => {
    setCollapsed(!collaps);
  };

  return (
    <Sider collapsible collapsed={collapsed} onClick={onCollapse}>
      <div className="logo">
        <h3>Photo Explorer</h3>
      </div>
      <Menu theme="dark" mode="inline">
        <Menu.Item key="1" icon={<PieChartOutlined />}>
        <NavLink to="/">overview</NavLink>
s        </Menu.Item>
        {/* <Menu.Item key="2" icon={<DesktopOutlined />}>
          home
        </Menu.Item> */}
        <SubMenu key="sub1" icon={<ContainerOutlined />} title="banner">
          <Menu.Item key="3">
            <NavLink to="/banner">upload banner</NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub2" icon={<AppstoreOutlined />} title="Categories">
          <Menu.Item key="4">
            <NavLink to="/category">Categories Table</NavLink>{" "}
          </Menu.Item>
          <Menu.Item key="5">
            <NavLink to="/add-category">Add Categories</NavLink>{" "}
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub3" icon={<PictureOutlined />} title="gallery">
          <SubMenu key="sub4" icon={<FileImageOutlined />} title="photos">
            <Menu.Item key="6">
            <NavLink to="/photos/all"> All photos</NavLink>
            </Menu.Item>
            <Menu.Item key="7">
            <NavLink to="/upload-photos">upload photos</NavLink>

            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub5" icon={<VideoCameraOutlined />} title="videos">
            <Menu.Item key="8">
              <NavLink to="/gallery/videos"> All videos</NavLink>
            </Menu.Item>
            <Menu.Item key="9">
              <NavLink to="/gallery/add-videos"> Add videos</NavLink>
            </Menu.Item>
          </SubMenu>
        </SubMenu>
        {/* <SubMenu key="sub6" icon={<img src={BlogImg} alt="blog"/>} title="blog"> */}
        <SubMenu key="sub6" icon={<ReadOutlined />} title="blog">
          <Menu.Item key="10">
            <NavLink to="/blog">Blog Table</NavLink>
          </Menu.Item>
          <Menu.Item key="11">
            <NavLink to="/add-blog">Add Blog</NavLink>
          </Menu.Item>
        </SubMenu>

        {/* <SubMenu key="sub2" icon={<UserOutlined />} title="User">
                    <Menu.Item key="6">Tom</Menu.Item>
                    <Menu.Item key="7">Bill</Menu.Item>
                    <Menu.Item key="8">Alex</Menu.Item>
                </SubMenu> */}
        {/* <Menu.Item key="10" icon={<FileOutlined />}>
          Files
        </Menu.Item> */}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
