import * as categoryActionTypes from "./categoryActionsTypes";
import axios from "../../../api/axios";
import { buildCategoryList } from "../../../interfaces/category.interface";

export const getCategories = (pageNo: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: categoryActionTypes.LOADING_CATEGORY });
      const response = await axios.get(`/categories/${pageNo}`, {
        headers: {
          Authorization: `Bearer ${getState().authReducer.token}`,
        },
      });
      if (response.status === 200) {
        dispatch({
          type: categoryActionTypes.LOADING_CATEGORY_SUCCESS,
          categories: buildCategoryList(
            response.data.doc,
            response.data.currentPage
          ),
          currentPage: response.data.currentPage,
          total: response.data.total,
        });
      }
    } catch (err) {
      dispatch({ type: categoryActionTypes.LOADING_CATEGORY_ERROR });
    }
  };
};

export const addCategories = (data: any, cb: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: categoryActionTypes.ADDING_CATEGORY,
      });
      const response = await axios.post(`/categories/add`, data);
      if (response && response.status === 200) {
        dispatch({
          type: categoryActionTypes.ADDING_CATEGORY_SUCCESS,
        });
        cb(true, null);
      } else {
        dispatch({
          type: categoryActionTypes.ADDING_CATEGORY_ERROR,
        });
        cb(false, true);
      }
    } catch (err) {
      dispatch({
        type: categoryActionTypes.ADDING_CATEGORY_ERROR,
      });
      cb(false, err);
    }
  };
};

export const editCategories = (data: any, id: any, cb: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: categoryActionTypes.EDITING_CATEGORY,
      });
      const response = await axios.patch(`/categories/update/${id}`, data);
      if (response && response.status === 200) {
        dispatch({
          type: categoryActionTypes.EDITING_CATEGORY_SUCCESS,
        });
        cb(true, null);
      } else {
        dispatch({
          type: categoryActionTypes.EDITING_CATEGORY_ERROR,
        });
        cb(false, true);
      }
    } catch (err) {
      dispatch({
        type: categoryActionTypes.EDITING_CATEGORY_ERROR,
      });
      cb(false, true);
    }
  };
};

export const deleteCategories = (id: any, cb: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: categoryActionTypes.DELETING_CATEGORY,
      });
      const response = await axios.delete(`/categories/delete/${id}`);
      if (response && response.status === 200) {
        dispatch({
          type: categoryActionTypes.DELETING_CATEGORY_SUCCESS,
        });
        cb(true, null);
      } else {
        dispatch({
          type: categoryActionTypes.DELETING_CATEGORY_ERROR,
        });
        cb(false, true);
      }
    } catch (err) {
      dispatch({
        type: categoryActionTypes.DELETING_CATEGORY_ERROR,
      });
      cb(false, err);
    }
  };
};

export const getCategoryByTitle = (title: string, cb: Function) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: categoryActionTypes.GETTING_CATEGORY_BY_TITLE });
      const response = await axios.get(`/categories/get-by-title/${title}`);
      if (response && response.status === 200) {
        dispatch({
          type: categoryActionTypes.GETTING_CATEGORY_BY_TITLE_SUCCESS,
          payload: response.data,
          title,
        });
        cb(true, null);
      } else {
        dispatch({
          type: categoryActionTypes.GETTING_CATEGORY_BY_TITLE_ERROR,
        });
        cb(false, true);
      }
    } catch (err) {
      dispatch({
        type: categoryActionTypes.GETTING_CATEGORY_BY_TITLE_ERROR,
      });
      cb(false, err);
    }
  };
};
