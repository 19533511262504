import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";

interface IProps {
  show: boolean;
  hide: () => void;
  selectedRow: Array<any>;
  onDeleteCategories: (data: any, multiple: boolean) => void;
  multiple?: boolean;
}

const DeleteCategory = (props: IProps) => {
  const category: any = useSelector<any>((state) => state.categoryReducer);
  const [categoryList, setCategoryList] = useState<any>([]);

  useEffect(() => {
    setCategoryList(props.selectedRow && props.selectedRow);
  }, [props]);

  return (
    <Modal
      title={`Delete Category`}
      visible={props.show}
      onOk={props.onDeleteCategories.bind(null, categoryList, props.multiple)}
      onCancel={props.hide}
      confirmLoading={category.deletingCategory}
    >
      <div className="modal-body">
        <p>
          Do you really want to delete{" "}
          {categoryList.length === 1
            ? "this category"
            : props.selectedRow.length + " categories"}
          ?
        </p>
      </div>
    </Modal>
  );
};
export default DeleteCategory;
