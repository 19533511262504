import React from "react";
import { Tabs, Row, Col } from "antd";
import AllBannerImage from "./components/AllBannerImage";
import ActiveBannerImage from "./components/ActiveBannerImage";
import { useLocation } from "react-router";
import UploadComponent from "./components/UploadComponent";
import { NavLink } from "react-router-dom";

const { TabPane } = Tabs;

const Banner = () => {
  const location = useLocation();

  return (
    <div className="main-contents">
      <div className="banner-content">
        <Tabs activeKey={`${location.pathname}`}>
          <TabPane
            tab={
              <NavLink to={`/banner`} exact>
                Upload Photos{" "}
              </NavLink>
            }
            key={`/banner`}
          >
            <UploadComponent />
          </TabPane>
          <TabPane
            tab={
              <NavLink to={`/banner/select`}> Select Uploaded Photos</NavLink>
            }
            key={`/banner/select`}
          >
            <Row gutter={16}>
              <Col span={10}>
                <ActiveBannerImage />
              </Col>
              <Col span={14}>
                <AllBannerImage />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Banner;
