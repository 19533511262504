import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";

interface IProps {
  url: string;
  show: boolean;
  closeModal: () => void;
}

function PreviewImage({ url, show, closeModal }: IProps) {
  return (
    <Modal
      visible={show}
      title="Preview"
      onCancel={closeModal}
      footer={[
        <Button type="primary" onClick={closeModal}>
          Ok
        </Button>,
      ]}
    >
      <img alt="img" style={{ width: "100%" }} src={url} />
    </Modal>
  );
}

export default PreviewImage;
