import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./containers/Login/Login";
import MasterRoutes from "./Routes/routes";
import "./App.scss";

const App = () => {
  return (
    <>
      <Switch>
        <Route path="/login" component={Login} exact />
        <Route component={MasterRoutes} />
      </Switch>
    </>
  );
};
export default App;
