import {
  Banner,
  BannerListResponse,
  IBannerReducer,
} from "./../../interfaces/banner.interface";
import * as bannerActionTypes from "../actions/bannerActions/bannerActionTypes";

const initState: IBannerReducer = {
  bannerList: [],
  hasMore: true,
  totalBannerImages: 0,
  currentPage: 1,
  selectedBanner: [],
};

export const bannerReducer = (
  state: IBannerReducer = initState,
  action: any
): IBannerReducer => {
  switch (action.type) {
    case bannerActionTypes.LOADING_BANNER_SUCCESS: {
      const data: BannerListResponse = action.payload;
      return {
        ...state,
        hasMore: data.hasMore,
        totalBannerImages: data.total,
        bannerList: state.bannerList.concat(data.banner),
        currentPage: action.page,
      };
    }
    case bannerActionTypes.GET_SELECTED_BANNER_SUCCESS: {
      const selectedBanner: Array<Banner> = action.payload;
      return {
        ...state,
        selectedBanner: selectedBanner,
      };
    }
    case bannerActionTypes.UPDATE_BANNER_SUCCESS: {
      const bannerIndex: number = state.bannerList.findIndex(
        (banner: Banner) => banner._id === action.id
      );
      const selectedBannerIndex: number = state.selectedBanner.findIndex(
        (banner: Banner) => banner._id === action.id
      );
      let newBannerList = [...state.bannerList];
      let newSelectedBannerList = [...state.selectedBanner];
      if (bannerIndex > -1) {
        newBannerList[bannerIndex].isActive = action.isActive;
      }
      if (selectedBannerIndex > -1) {
        newSelectedBannerList.splice(selectedBannerIndex, 1);
      } else {
        newSelectedBannerList.push({
          isActive: action.isActive,
          _id: action.id,
          url: action.url,
        });
      }
      return {
        ...state,
        bannerList: [...newBannerList],
        selectedBanner: [...newSelectedBannerList],
      };
    }

    case bannerActionTypes.DELETE_BANNER_SUCCESS: {
      let newBannerList = [...state.bannerList];
      let newSelectedBannerList = [...state.selectedBanner];
      const bannerIndex: number = state.bannerList.findIndex(
        (banner: Banner) => banner._id === action.id
      );
      const selectedBannerIndex: number = state.selectedBanner.findIndex(
        (banner: Banner) => banner._id === action.id
      );
      if (bannerIndex > -1) {
        newBannerList.splice(bannerIndex, 1);
      }
      if (selectedBannerIndex > -1) {
        newSelectedBannerList.splice(selectedBannerIndex, 1);
      }
      return {
        ...state,
        bannerList: [...newBannerList],
        selectedBanner: [...newSelectedBannerList],
      };
    }

    case bannerActionTypes.ADD_BANNER_SUCCESS: {
      let newBannerList = [...state.bannerList];
      newBannerList = action.payload.concat(state.bannerList);

      return {
        ...state,
        bannerList: [...newBannerList],
      };
    }
    default:
      return state;
  }
};
