export interface CategoryReducer {
  totalPage: number;
  currentPage: number;
  categories: Array<any>;
  loadingCategory: boolean;
  addingCategory: boolean;
  editingCategory: boolean;
  deletingCategory: boolean;
  allCategories: Array<any>;
}

export const buildCategoryList = (
  categories: Array<any>,
  currentPage: number
) => {
  let categoryList = categories.map((category, index) => ({
    ...category,
    key: (currentPage - 1) * 10 + index + 1,
  }));
  return categoryList;
};

export const buildCategoryFormData = (data: any): FormData => {
  const formData = new FormData();
  data && data.title && formData.append("title", data.title);
  data &&
    data.tags &&
    data.tags.forEach((tag: string) => {
      formData.append("tags", tag);
    });
  data &&
    data.thumbnailImg &&
    formData.append("thumnailImg", data.thumbnailImg);
  return formData;
};
