import { Col, Image, message, Row, Typography } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GallerySkeleton } from "../../../common/components/skeleton";
import { apiError } from "../../../common/helpers/common";
import { Banner, IBannerReducer } from "../../../interfaces/banner.interface";
import { IHttpError } from "../../../interfaces/http.interface";
import placeholderImg from "../../../assets/images/placeholderImg.jpg";
import {
  getSelectedBannerImages,
  updateBanner,
} from "../../../store/actions/bannerActions/bannerActionCreators";

function ActiveBannerImage() {
  const banner: IBannerReducer = useSelector<any, any>(
    (state) => state.bannerReducer
  );
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [settingBanner, setSettingBanner] = useState<boolean>(false);

  const getSelectedBannerHandler = useCallback(() => {
    setLoading(true);
    dispatch(
      getSelectedBannerImages((res: any, err: IHttpError) => {
        setLoading(false);
        if (err) {
          console.log(err);
        }
      })
    );
  }, [dispatch]);

  const selectImageHandler = (id: string, e: any) => {
    if (settingBanner) {
      return;
    }
    setSettingBanner(true);
    dispatch(
      updateBanner(id, e.target.checked, (res: boolean, err: IHttpError) => {
        setSettingBanner(false);
        if (res) {
          message.success(
            `Banner image ${
              e.target.checked ? "selected" : `unselected`
            } successfully.`
          );
        }
        if (err) {
          apiError(err);
        }
      })
    );
  };

  useEffect(() => {
    if (banner.selectedBanner.length === 0) {
      getSelectedBannerHandler();
    }
  }, [getSelectedBannerHandler, banner.selectedBanner.length]);

  return (
    <div className="banner-active-img-container">
      <div className="header">
        <Typography.Title level={5}>Selected Images</Typography.Title>
      </div>
      <Row gutter={20}>
        {banner.selectedBanner.map((selectedBanner: Banner) => (
          <Col span={12} key={selectedBanner._id}>
            <div className="banner-photos-wrap active-img">
              <Checkbox
                checked={selectedBanner.isActive}
                onChange={selectImageHandler.bind(null, selectedBanner._id)}
                className="check-box"
              />
              <div className="select-upload-img mb-3">
                <Image src={selectedBanner.url} fallback={placeholderImg} />
              </div>
            </div>
          </Col>
        ))}
      </Row>
      {loading && (
        <div className="d-flex">
          <GallerySkeleton />
        </div>
      )}
    </div>
  );
}

export default ActiveBannerImage;
