import { Layout, Menu, Dropdown, Avatar } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import { logout } from "../../../store/actions/authActions/authActionCreators";
import { useDispatch } from "react-redux";

const { Header } = Layout;

const Navbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const menu = (
    <Menu>
      {/* <Menu.Item key="0">
        <a href="https://www.antgroup.com">Edit Profile</a>
      </Menu.Item>

      <Menu.Divider /> */}
      <Menu.Item key="1" onClick={() => logoutHandler()}>
        Logout
      </Menu.Item>
    </Menu>
  );
  const logoutHandler = () => {
    dispatch(
      logout(() => {
        history.push("/login");
      })
    );
  };

  return (
    <Header className="site-layout-background" style={{ padding: 0 }}>
      <div className="search"></div>
      <div className="ml-auto w-10 h-100">
        <Dropdown overlay={menu} trigger={["click"]}>
          <div
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            <Avatar style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}>
              U
            </Avatar>{" "}
            <DownOutlined />
          </div>
        </Dropdown>
      </div>
    </Header>
  );
};

export default Navbar;
