import React, { useState } from "react";
import { Breadcrumb, Input, Button, Upload, Typography, message } from "antd";
import { HomeOutlined, TableOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import { PlusOutlined } from "@ant-design/icons";
import { buildBlogRequestBody } from "../../../interfaces/blog.interface";
import { createBlog } from "../../../store/actions/blogActions/blogActionCreators";

const { Text } = Typography;

const AddBlog = () => {
  const blog: any = useSelector<any>((state) => state.blogReducer);
  const dispatch = useDispatch();

  const [input, setInput] = useState({
    title: "",
    paragraph_1: "",
    paragraph_2: "",
  });
  const [error, setError] = useState<any>({
    title: false,
    paragraph_1: false,
    paragraph_2: false,
    file: false,
  });
  const [fileList, setFileList] = useState<Array<any>>([]);

  const inputChangeHandler = (field: string, e: any) => {
    setInput({ ...input, [field]: e.target.value });
    setError({ ...error, [field]: false });
  };

  const submitCategory = () => {
    const titleError = validateFields(input.title, 3, "Title");
    const paragraph2Error = validateFields(
      input.paragraph_2,
      3,
      "Paragraph two"
    );
    const paragraph1Error = validateFields(
      input.paragraph_1,
      3,
      "Paragraph one"
    );
    const fileError = validateFields(fileList, 2, "file");
    if (!titleError && !paragraph2Error && !paragraph1Error && !fileError) {
      setError({
        ...error,
        title: false,
        paragraph_1: false,
        paragraph_2: false,
        file: false,
      });
      const data = buildBlogRequestBody({ ...input, files: fileList });
      dispatch(
        createBlog(data, (res: any, err: any) => {
          if (res) {
            setInput({
              title: "",
              paragraph_1: "",
              paragraph_2: "",
            });
            setFileList([]);
            message.success("Blog added successfully.");
          }
          if (err) {
            // message.error(
            //   (err.response &&
            //     err.response.data &&
            //     err.response.data.message) ||
            //     "Couldn't add blog right now."
            // );
             message.error( "Please Upload Exactly Two Images");
          }
        })
      );
    } else {
      setError({
        ...error,
        title: titleError,
        paragraph_1: paragraph1Error,
        paragraph_2: paragraph2Error,
        file: fileError,
      });
    }
  };

  const validateFields = (value: any, minLength: any, field: any) => {
    if (value.length === 0) {
      return `${
        field === "file" ? "Upload two images for blog" : field + " is required"
      }`;
    }
    if (value.length >= minLength) {
      return false;
    }
    if (value.length < minLength) {
      return `${
        field === "file"
          ? "Upload two images for blog"
          : field + " must be of minLength character long"
      }`;
    }

    return false;
  };

  const handleChange = ({ fileList }: any) => {
    setFileList(fileList);
    setError({ ...error, file: false });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div className="main-contents">
      <Breadcrumb>
        <Breadcrumb.Item href="/home">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/blog">
          <TableOutlined />
          <span>Blog List</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="active">Add Category</Breadcrumb.Item>
      </Breadcrumb>
      <div className="content-title">
        <h3>Blog</h3>
        <p>Add blog</p>
      </div>
      <div className="content-add-form">
        <div className="form-wraps">
          <div className="form-group">
            <label> Title: </label>
            <Input
              value={input.title}
              onChange={(event: any) => inputChangeHandler("title", event)}
              className={error.title ? "control-error" : ""}
            />
            {error.title && <Text type="danger">{error.title}</Text>}
          </div>
          <div className="form-group">
            <label> Paragraph one: </label>
            <TextArea
              rows={3}
              value={input.paragraph_1}
              onChange={(event: any) =>
                inputChangeHandler("paragraph_1", event)
              }
              className={error.paragraph_1 ? "control-error" : ""}
            />
            {error.paragraph_1 && (
              <Text type="danger">{error.paragraph_1}</Text>
            )}
          </div>
          <div className="form-group">
            <label> Paragraph two: </label>
            <TextArea
              rows={3}
              value={input.paragraph_2}
              onChange={(event: any) =>
                inputChangeHandler("paragraph_2", event)
              }
              className={error.paragraph_2 ? "control-error" : ""}
            />
            {error.paragraph_2 && (
              <Text type="danger">{error.paragraph_2}</Text>
            )}
          </div>
          <div className="form-group">
            <label> Upload exact two images for blog </label>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={handleChange}
              beforeUpload={() => false}
              multiple
              showUploadList={{ showPreviewIcon: false }}
            >
              {uploadButton}
            </Upload>
            {error.file && <Text type="danger">{error.file}</Text>}
          </div>
          <div className="form-group">
            <Button
              onClick={submitCategory}
              loading={blog.creatingBlog}
              type="primary"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBlog;
