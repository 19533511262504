import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { apiError } from "../../../common/helpers/common";
import { IHttpError } from "../../../interfaces/http.interface";
import { deleteBanner } from "../../../store/actions/bannerActions/bannerActionCreators";

interface IProps {
  show: boolean;
  closeModal: () => void;
  id: string;
}

function DeleteBannerModal({ show, closeModal, id }: IProps) {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState<boolean>(false);

  const deleteBannerHandler = (id: string) => {
    setDeleting(true);
    dispatch(
      deleteBanner(id, (res: boolean, err: IHttpError) => {
        setDeleting(false);
        if (err) {
          apiError(err);
        }
        if (res) {
          closeModal();
        }
      })
    );
  };

  return (
    <Modal
      visible={show}
      onCancel={closeModal}
      title="Delete Image"
      okText="Delete"
      onOk={deleteBannerHandler.bind(null, id)}
      confirmLoading={deleting}
    >
      Are you sure you want to delete this image?
    </Modal>
  );
}

export default DeleteBannerModal;
