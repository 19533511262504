

import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Input, Space, Table, TablePaginationConfig, message, Image } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import placeholderImage from '../../../../assets/images/placeholderImg.jpg';

import {
    HomeOutlined,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    TableOutlined,
} from "@ant-design/icons";
import { editVideos, getAllVideos, deleteVideoUrl } from '../../../../store/actions/GalleyActions/galleryActionCreators';
import EditVideos from './components/editVideos';
import { buildVideoRequestBody } from '../../../../interfaces/gallery.interface';
import DeleteVideo from './components/deleteVideo';


interface Pagination {
    current: number;
    pageSize: number;
    total: number;
}

const Video = () => {
    const video: any = useSelector<any>((state) => state.galleryReducer);
    const dispatch = useDispatch();
    const [editVideoVisible, setEditVideoVisible] = useState(false);
    const [deleteVideoVisible, setDeleteVideoVisible] = useState(false);
    const [selectedRow, setSelectedRow] = useState({ _id: null });


    const editVideo = (text: any) => {
        setEditVideoVisible(true);
        setSelectedRow(text);
    };

    const deleteVideo = (id: any) => {
        setDeleteVideoVisible(true);
        setSelectedRow(id);

    };


    const hideVideoModal = () => {
        setEditVideoVisible(false);
        setDeleteVideoVisible(false);
    };

    const onUpdateVideo = (values: any) => {
        const data: FormData = buildVideoRequestBody(values);
        dispatch(
            editVideos(data, selectedRow && selectedRow?._id, (res: any, err: any) => {
                setEditVideoVisible(false);
                message.success("Video Sucessfully Updated");
                dispatch(getAllVideos(video.currentPage));
            })
        );
    };
    const onDeleteVideo = (id: any) => {
        dispatch(
            deleteVideoUrl(id, (res: any, err: any) => {
                console.log("res");
                setDeleteVideoVisible(false);
                message.success("Sucessfully Deleted");
                dispatch(getAllVideos(video.videoCurrentPage));
            })
        );
    };

    const pagination: Pagination = {
        current: video.videoCurrentPage,
        pageSize: 12,
        total: video.totalPage,
    };

    const handleTableChange = (pagination: TablePaginationConfig) => {
        dispatch(getAllVideos(pagination.current as number));
    };

    useEffect(() => {
        dispatch(getAllVideos(1));
    }, [dispatch]);

    const columns = [
        {
            title: "S.N",
            dataIndex: "key",
            key: "key",
            render: (key: any) => <span>{key}.</span>,
        },
        {
            title: "title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Thumbnail",
            dataIndex: "thumbnail",
            key: "thumbnail",
            render: (url: string) => <span className="img-wrap"> <Image src={url ? url : placeholderImage} /></span>
        },


        {
            title: "Action",
            key: "action",
            render: (_text: any) => {
                return (
                    <Space size="middle">
                        <Button
                            type="link"
                            size="small"
                            className="actions"
                            onClick={editVideo.bind(null, _text)}
                        >
                            <EditOutlined />
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            className="actions"
                            danger
                            onClick={deleteVideo.bind(null, _text)}
                        >
                            <DeleteOutlined />
                        </Button>
                    </Space>
                );
            },
        },
    ];

    return (
        <div className="content-table">
            <Breadcrumb>
                <Breadcrumb.Item href="/home">
                    <HomeOutlined />
                </Breadcrumb.Item>

                <Breadcrumb.Item className="active">
                    <TableOutlined />
                    <span className="pl-2">Video Collection</span>
                </Breadcrumb.Item>
            </Breadcrumb>
            <div className="content-title">
                <h3>Video Detail</h3>
                <p>Video table show all the Video added by the user</p>
            </div>
            <div className="content-header d-flex mb-20">
                <Link to="/gallery/add-videos">
                    <Button type="primary" icon={<PlusOutlined />}>
                        Add Video
                        </Button>
                </Link>
                
                <div className="search-wrap ml-auto">
                    <div className="form-group-wrap">
                        <Input />
                    </div>
                </div>
            </div>
            <div className="card-shadow">
                <div className="table-responsive">
                    <Table
                        columns={columns}
                        dataSource={video.videos}
                        loading={video.videosLoading}
                        pagination={pagination}
                        onChange={handleTableChange}
                    />
                </div>

                <EditVideos
                    show={editVideoVisible}
                    hide={hideVideoModal}
                    selectedRow={selectedRow}
                    onUpdateVideos={onUpdateVideo}
                />
                <DeleteVideo
                    show={deleteVideoVisible}
                    hide={hideVideoModal}
                    selectedRow={selectedRow}
                    onDeleteVideos={onDeleteVideo}
                />
            </div>
        </div >
    )
}
export default Video;