import { IAuthReduder } from "./../../interfaces/auth.interface";
import * as authActionTypes from "../actions/authActions/authActionTypes";

const initState: IAuthReduder = {
  isLoading: false,
  token: null,
  isAuthenticated:false,
};

export const authReducer = (state = initState, action: any) => {
  switch (action.type) {
    case authActionTypes.LOGIN_START:
      return {
        ...state,
        isLoading: true,
      };
    case authActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated:true,
        token: action.token,
      };
    case authActionTypes.LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
      };
      case authActionTypes.LOGOUT:
        return{
          ...state,
          isAuthenticated:false,
          token:null,
        }
    default:
      return state;
  }
};
