import React, { useState, useEffect } from "react";
import { Modal, Input, Select, Upload } from "antd";
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";

const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);

interface IProps {
  show: boolean;
  hide: () => void;
  selectedRow: any;
  onUpdateCategories: (data: any) => void;
}

const EditCategory = (props: IProps) => {
  const category: any = useSelector<any>((state) => state.categoryReducer);
  const [title, setTitle] = useState<string>("");
  const [tags, setTags] = useState<Array<string>>([]);
  const [fileList, setFileList] = useState<Array<any>>([]);
  const [previewImage, setPreviewImage] = useState<any>();
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);


  const titleHandler = (e: any) => {
    setTitle(e.target.value);
  };

  const getBase64 =(file:any) =>{
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  const  handleCancel = () => {
    setPreviewVisible(false)
  
   };
   const  handlePreview = async (file:any) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview,)
      setPreviewVisible(true)
    };
  
   

  const handleTagChange = (value: any) => {
    setTags(value);
  };

  const handleChange = (e: any) => {
    setFileList(e.fileList);
  };

  useEffect(() => {
    props.selectedRow &&
      props.selectedRow.title &&
      setTitle(props.selectedRow.title);
    props.selectedRow &&
      props.selectedRow.tags &&
      setTags(props.selectedRow.tags);
    props.selectedRow &&
      props.selectedRow.thumbnailUrl &&
      setFileList([
        {
          uid: 1,
          name: "",
          status: "done",
          url: props.selectedRow.thumbnailUrl,
        },
      ]);
  }, [props.selectedRow]);

  return (
    <Modal
      title="Edit Category"
      visible={props.show}
      onOk={props.onUpdateCategories.bind(null, { title, tags, fileList })}
      onCancel={props.hide}
      confirmLoading={category.editingCategory}
    >
      <div className="form-wraps">
        <div className="form-group">
          <label> Title: </label>
          <Input value={title} onChange={titleHandler} />
        </div>
        <div className="form-group">
          <label> Tags: </label>
          <Select
            mode="tags"
            onChange={handleTagChange}
            className="form-control"
            value={tags}
          />
        </div>
        <div className="form-group">
          <label> Thumbnail Image: </label>
          <Upload
            listType="picture-card"
            fileList={fileList}
            onChange={handleChange}
            beforeUpload={() => false}
            maxCount={1}
            onPreview={handlePreview}
            showUploadList={{ showRemoveIcon: false }}
          >
            {uploadButton}
          </Upload>
        </div>
      </div>
      <Modal
          visible={previewVisible}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
    </Modal>
  );
};
export default EditCategory;
