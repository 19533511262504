import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Overview from "../containers/Dashborad/Overview/overview";
import Banner from "../containers/banner/banner";

import AddCategory from "../containers/Dashborad/Category/components/addCategory";
import categoryTable from "../containers/Dashborad/Category/categoryTable";
import { Layout } from "antd";
import Sidebar from "../containers/Layouts/Sidebar/sidebar";
import Navbar from "../containers/Layouts/Navbar/navbar";
import Blog from "../containers/Dashborad/Blog/Blog";
import ViewBlogById from "../containers/Dashborad/Blog/component/ViewBlogById";
import { checkAuth } from "../store/actions/authActions/authActionCreators";
import { useDispatch } from "react-redux";
import AddBlog from "../containers/Dashborad/Blog/AddBlog";
import Photos from "../containers/Dashborad/Gallery/Photos/photos";
import UploadPhotos from "../containers/Dashborad/Gallery/Photos/components/uploadPhotos";
import { checkAuthStatus } from "../util/auth.util";
import AddVideos from "../containers/Dashborad/Gallery/Videos/components/addVideos";
import video from "../containers/Dashborad/Gallery/Videos/video";
const { Content, Footer } = Layout;

const Routes = () => {
  // const token = useSelector<any>((state) => state.authReducer.token);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  if (!checkAuthStatus()) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Sidebar />
        <Layout className="site-layout">
          <Navbar />
          <Content style={{ margin: "0px 20px 0 300px" }}>
            <Switch>
              <Route path="/banner" component={Banner} />
              <Route path="/add-category" component={AddCategory} />
              <Route path="/category" component={categoryTable} />
              <Route path="/photos/:title" component={Photos} />
              <Route path="/upload-photos" component={UploadPhotos} />
              <Route path="/add-blog" component={AddBlog} />
              <Route path="/blog/:id" component={ViewBlogById} />
              <Route path="/blog" component={Blog} />
              <Route path="/gallery/add-videos" component={AddVideos} />
              <Route path="/gallery/videos" component={video} />
              <Route path="/" component={Overview} />
              {/* <Route path="/category" component={AddCategory} />
              <Route path="/categoryTable" component={categoryTable} /> */}
              {/* <Route component={NoMatch} /> */}
            </Switch>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Photo Explorer ©2020 Created by STARTUP
          </Footer>
        </Layout>
      </Layout>
    </>
  );
};

export default Routes;
