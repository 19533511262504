import {
  buildUpdatePhotosRequestBody,
  buildUpdateVideoRequestBody,
  buildVideoList,
} from "./../../../interfaces/gallery.interface";
import { IHttpRes } from "./../../../interfaces/http.interface";
import * as galleryActionTypes from "./galleryActionTypes";
import axios from "../../../api/axios";
import {
  buildPhotosRequestBody,
  PhotosListInterface,
} from "../../../interfaces/gallery.interface";
import { Dispatch } from "react";

export const getAllPhotos = (
  pageNo: number,
  categoryTitle: string,
  cb: Function
) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: galleryActionTypes.PHOTOS_LOADING,
      });
      const response: IHttpRes<PhotosListInterface> = await axios.get(
        `/gallery/photos/${pageNo}/${categoryTitle}`
      );
      if (response.status === 200) {
        dispatch({
          type: galleryActionTypes.PHOTOS_LOADING_SUCCESS,
          payload: response.data,
          currentPage: pageNo,
        });
        cb(true, false);
      } else {
        dispatch({
          type: galleryActionTypes.PHOTOS_LOADING_ERROR,
        });
        cb(false, true);
      }
    } catch (err) {
      dispatch({
        type: galleryActionTypes.PHOTOS_LOADING_ERROR,
      });
      cb(false, err);
    }
  };
};

export const createPhotos = (data: any, cb: any) => {
  const formData = buildPhotosRequestBody(data);
  return async (dispatch: any) => {
    try {
      dispatch({
        type: galleryActionTypes.UPLOAD_PHOTOS_LOADING,
      });
      const response = await axios.post(`/gallery/photo/add/`, formData);
      if (response.status === 200) {
        dispatch({
          type: galleryActionTypes.UPLOAD_PHOTOS_LOADING_SUCCESS,
        });
        cb(true, null);
      } else {
        dispatch({
          type: galleryActionTypes.UPLOAD_PHOTOS_LOADING_ERROR,
        });
        cb(false, true);
      }
    } catch (err) {
      dispatch({
        type: galleryActionTypes.UPLOAD_PHOTOS_LOADING_ERROR,
      });
      cb(false, err);
    }
  };
};

export const updatePhotos = (data: any, id: string, cb: Function) => {
  const patload: FormData = buildUpdatePhotosRequestBody(data);
  return async (dispatch: Dispatch<any>) => {
    try {
      const response: IHttpRes<any> = await axios.patch(
        `/gallery/photo/update/${id}`,
        patload
      );
      dispatch({
        type: galleryActionTypes.UPDATING_PHOTOS_SUCCESS,
        payload: {
          ...data,
          url: response.data[0]?.url,
          category: response.data[0]?.category,
        },
        id: id,
      });
      cb(true, false);
    } catch (err) {
      cb(false, err);
    }
  };
};

export const deletePhoto = (id: Array<string>, cb: Function) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await axios.delete(`/gallery/photo/delete/${id}`);
      dispatch({ type: galleryActionTypes.DELETING_PHOTOS_SUCCESS, id: id });
      cb(true, null);
    } catch (err) {
      cb(null, err);
    }
  };
};

export const addVideo = (data: FormData, cb: Function) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: galleryActionTypes.ADDING_VIDEOS });
      const response = await axios.post("/gallery/video/add", data);
      if (response.status === 200) {
        dispatch({ type: galleryActionTypes.ADDING_VIDEOS_SUCCESS });
        cb(true, null);
      } else {
        dispatch({ type: galleryActionTypes.ADDING_VIDEOS_ERROR });
        cb(null, true);
      }
    } catch (err) {
      dispatch({ type: galleryActionTypes.ADDING_VIDEOS_ERROR });
      cb(null, err);
    }
  };
};

export const createFeaturedVideo = (data: FormData, cb: Function) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: galleryActionTypes.FEATURED_VIDEO });
      const response = await axios.post("/gallery/video/addFeaturedVideo", data);
      if (response.status === 200) {
        dispatch({ type: galleryActionTypes.FEATURED_VIDEO_SUCCESS });
        cb(true, null);
      } else {
        dispatch({ type: galleryActionTypes.FEATURED_VIDEO_ERROR });
        cb(null, true);
      }
    } catch (err) {
      dispatch({ type: galleryActionTypes.FEATURED_VIDEO_ERROR });
      cb(null, err);
    }
  };
};

export const getFeaturedVideo = (cb: Function) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: galleryActionTypes.GET_FEATURED_VIDEO });
      const response = await axios.get("/gallery/video/getFeaturedVideo");
      if (response.status === 200) {
        dispatch({ type: galleryActionTypes.GET_FEATURED_VIDEO_SUCCESS });
        cb(response.data, null);
      } else {
        dispatch({ type: galleryActionTypes.GET_FEATURED_VIDEO_ERROR });
        cb(null, true);
      }
    } catch (err) {
      dispatch({ type: galleryActionTypes.GET_FEATURED_VIDEO_ERROR });
      cb(null, err);
    }
  };
};
export const getAllVideos = (pageNo: number) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: galleryActionTypes.VIDEOS_LOADING });
      const response = await axios.get(`gallery/video/${pageNo}/all/`);
      if (response.status === 200) {
        dispatch({
          type: galleryActionTypes.VIDEOS_LOADING_SUCCESS,
          payload: buildVideoList(
            response.data.videos,
            response.data.currentPage
          ),
          currentPage: response.data.currentPage,
          totalPage: response.data.totalPage,
        });
        console.log("response", response.data);
      } else {
        dispatch({ type: galleryActionTypes.VIDEOS_LOADING_ERROR });
      }
    } catch (err) {
      dispatch({ type: galleryActionTypes.VIDEOS_LOADING_ERROR });
    }
  };
};

export const deleteVideoUrl = (id: string, cb: Function) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: galleryActionTypes.DELETING_VIDEOS });
      const response = await axios.delete(`gallery/video/delete/${id}`);
      if (response.status === 200) {
        dispatch({
          type: galleryActionTypes.DELETING_VIDEOS_SUCCESS,
        });
        cb(true, false);
      } else {
        dispatch({ type: galleryActionTypes.DELETING_VIDEOS_ERROR });
        cb(false, true);
      }
    } catch (err) {
      dispatch({ type: galleryActionTypes.DELETING_VIDEOS_ERROR });
      cb(false, err);
    }
  };
};

export const editVideos = (data: any, id: any, cb: any) => {
  const payload: FormData = buildUpdateVideoRequestBody(data);
  return async (dispatch: any) => {
    try {
      dispatch({ type: galleryActionTypes.EDITING_VIDEOS });
      const response = await axios.patch(`/gallery/video/update/${id}`, payload);
      if (response.status === 200) {
        dispatch({
          type: galleryActionTypes.EDITING_VIDEOS_SUCCESS,
          payload: buildVideoList(
            response.data.videos,
            response.data.currentPage
          ),
        });
        cb(true, false);
      } else {
        dispatch({ type: galleryActionTypes.EDITING_VIDEOS_ERROR });
      }
    } catch (err) {
      dispatch({ type: galleryActionTypes.EDITING_VIDEOS_ERROR });
      cb(false, err);
    }
  };
};
