import React, { useState, useEffect } from 'react'
import { Modal } from 'antd';
import { useSelector } from 'react-redux';

interface IProps {
  show: boolean;
  hide: () => void;
  selectedRow: any;
  onDeleteVideos: (data: any) => void;
}

const DeleteVideo = (props: IProps) => {
  const video: any = useSelector<any>((state) => state.galleryReducer);
  const [videoList, setVideoList] = useState<any>({});

  console.log("videoList", videoList)

  useEffect(() => {
    setVideoList(props.selectedRow && props.selectedRow);

  }, [props])
  
  return (
    <Modal title={`Delete ${videoList.title}`} visible={props.show} onOk={props.onDeleteVideos.bind(null, videoList._id)} onCancel={props.hide} confirmLoading={video.deletingVideos}>
      <div className="modal-body">
          <p>Do you really want to delete <span className="bold">{videoList.title} ?</span></p>
      </div>
    </Modal>
  )
}
export default DeleteVideo;