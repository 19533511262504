import React, { useState } from "react";
import {
  Breadcrumb,
  Select,
  Button,
  Upload,
  Typography,
  Checkbox,
  message,
} from "antd";
import { HomeOutlined, TableOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { createPhotos } from "../../../../../store/actions/GalleyActions/galleryActionCreators";
import { getCategoryByTitle } from "../../../../../store/actions/categoryActions/categoryActionCreators";
const { Text } = Typography;

const { Option } = Select;

const UploadPhotos = () => {
  const photos: any = useSelector<any>((state) => state.galleryReducer);
  const category: any = useSelector<any>((state) => state.categoryReducer);
  const [categoryLoader, setCategoryLoader] = useState<boolean>(false);

  const dispatch = useDispatch();

  const [error, setError] = useState<any>({
    file: false,
  });
  const [fileList, setFileList] = useState<Array<any>>([]);
  const [featured, setFeatured] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const featureHandler = (e: any) => {
    setFeatured(e.target.checked);
  };
  const submitPhotos = () => {
    const fileError = validateFields(fileList, 1, "file");
    if (!fileError) {
      setError({
        ...error,
        file: false,
      });
      const data = {
        featured,
        selectedCategory,
        files: fileList,
      };
      dispatch(
        createPhotos(data, (res: any, err: any) => {
          if (res) {
            setSelectedCategory([]);
            setFeatured(false);
            setFileList([]);
            message.success("photos added successfully.");
          }
          if (err) {
            message.error(
              (err.response &&
                err.response.data &&
                err.response.data.message) ||
                "Couldn't add photos right now."
            );
          }
        })
      );
    } else {
      setError({
        ...error,
        file: fileError,
      });
    }
  };

  const validateFields = (value: any, minLength: any, field: any) => {
    if (value.length === 0) {
      return `${
        field === "file"
          ? "Upload four images for photos"
          : field + " is required"
      }`;
    }
    if (value.length >= minLength) {
      return false;
    }
    if (value.length < minLength) {
      return `${
        field === "file"
          ? "Upload four images for photos"
          : field + " must be of minLength character long"
      }`;
    }

    return false;
  };

  const handleChange = ({ fileList }: any) => {
    setFileList(fileList);
    setError({ ...error, file: false });
  };

  const onChangeCategory = (value: any) => {
    setSelectedCategory(value);
  };

  const onFocus = () => {
    if (category.allCategories.length === 0) {
      setCategoryLoader(true);
      dispatch(
        getCategoryByTitle("all", (res: any, err: any) => {
          setCategoryLoader(false);
          if (err) {
            console.log(err);
          }
        })
      );
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div className="main-contents">
      <Breadcrumb>
        <Breadcrumb.Item href="/home">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/photos">
          <TableOutlined />
          <span>Photos Collection</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="active">Upload Photos</Breadcrumb.Item>
      </Breadcrumb>
      <div className="content-title">
        <h3>Photos</h3>
        <p>Upload Photos</p>
      </div>
      <div className="content-add-form">
        <div className="form-wraps">
          <div className="form-group">
            <label> Upload photos </label>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={handleChange}
              beforeUpload={() => false}
              multiple
              showUploadList={{ showPreviewIcon: false }}
            >
              {uploadButton}
            </Upload>
            {error.file && <Text type="danger">{error.file}</Text>}
          </div>
          <div className="form-group">
            <label>Select Category</label>
            <Select
              mode="multiple"
              showSearch
              style={{ width: 500 }}
              placeholder="Select category"
              optionFilterProp="children"
              onChange={onChangeCategory}
              onFocus={onFocus}
              value={selectedCategory}
              loading={categoryLoader}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {category.allCategories &&
                category.allCategories.map((cat: any) => (
                  <Option key={cat._id} value={cat._id}>
                    {cat.title}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="form-group">
            <label> Featured: </label>
            <Checkbox checked={featured} onChange={featureHandler}>
              Featured
            </Checkbox>
            {error.title && <Text type="danger">{error.title}</Text>}
          </div>
          <div className="form-group">
            <Button
              onClick={submitPhotos}
              loading={photos.uploadLoading}
              type="primary"
            >
              Submit{" "}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadPhotos;
