export const LOADING_BLOG = "LOADING_BLOG";
export const LOADING_BLOG_SUCCESS = "LOADING_BLOG_SUCCESS";
export const LOADING_BLOG_ERROR = "LOADING_BLOG_ERROR";

export const LOADING_BLOGBYID = "LOADING_BLOGBYID";
export const LOADING_BLOGBYID_SUCCESS = "LOADING_BLOGBYID_SUCCESS";
export const LOADING_BLOGBYID_ERROR = "LOADING_BLOGBYID_ERROR";

export const CREATING_BLOG = "CREATING_BLOG";
export const CREATING_BLOG_SUCCESS = "CREATING_BLOG_SUCCESS";
export const CREATING_BLOG_ERROR = "CREATING_BLOG_ERROR";

export const DELETE_BLOG = "DELETE_BLOG";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_ERROR = "DELETE_BLOG_ERROR";

export const EDITING_BLOG = "EDITING_BLOG";
export const EDITING_BLOG_SUCCESS = "EDITING_BLOG_SUCCESS";
export const EDITING_BLOG_ERROR = "EDITING_BLOG_ERROR";
