import React, { useEffect, useState } from 'react'

import { Row, Col, Select } from 'antd';
import ReactEcharts from "echarts-for-react";

import Photos from '../../../assets/images/overview/photos.svg'
import Videos from '../../../assets/images/overview/videos.svg'
import Blogs from '../../../assets/images/overview/blogs.svg'
import Banners from '../../../assets/images/overview/banners.svg'
import { useDispatch } from 'react-redux';
import { IHttpError } from '../../../interfaces/http.interface';
import { getTotalOverview } from '../../../store/actions/bannerActions/bannerActionCreators';
const { Option } = Select;


const Overview = () => {
    const dispatch = useDispatch();
    const [totalData, setTotalData] = useState<any>()
    useEffect(() => {
        dispatch(getTotalOverview((res:any, err:IHttpError) =>{
                setTotalData(res)
        }) )
    }, [dispatch])

    let lineChartOptions = {
        xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            smooth: true
        }]
    }
    const handleChange = (value:any) => {
        console.log(`selected ${value}`);
    }
    return (
        <div className="overview-wrapper">
            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <div className="overview-card card-shadow">
                        <div className="d-flex align-items-center">
                            <div className="icon-circle">
                                <img src={Photos} alt="photos" className="img-fluid"/>
                            </div>
                            <div className="wraps">
                                <p>Photos</p>
                                <h3>{totalData?.photosCount}</h3>
                            </div>
                        </div>

                    </div>
                </Col>
                <Col span={6}>
                    <div className="overview-card card-shadow">
                        <div className="d-flex align-items-center">
                            <div className="icon-circle">
                            <img src={Videos} alt="photos" className="img-fluid"/>
                            </div>
                            <div className="wraps">
                                <p>videos</p>
                                <h3>{totalData?.videosCount}</h3>
                            </div>
                        </div>

                    </div>
                </Col>
                <Col span={6}>
                    <div className="overview-card card-shadow">
                        <div className="d-flex align-items-center">
                            <div className="icon-circle">
                            <img src={Blogs} alt="photos" className="img-fluid"/>
                            </div>
                            <div className="wraps">
                                <p>blog</p>
                                <h3>{totalData?.blogsCount}</h3>
                            </div>
                        </div>

                    </div>
                </Col>
                <Col span={6}>
                    <div className="overview-card card-shadow">
                        <div className="d-flex align-items-center">
                            <div className="icon-circle">
                            <img src={Banners} alt="photos" className="img-fluid"/>
                            </div>
                            <div className="wraps">
                                <p>Banner</p>
                                <h3>{totalData?.bannerCount}</h3>

                            </div>
                        </div>

                    </div>
                </Col>

            </Row>
            <Row gutter={[16, 16]} className="mt-3">
                <Col span={24}>
                    <div className="overview-card card-shadow">
                        <div className="total-people-visited">
                            <div className="heading d-flex">
                                <h2>Records of Total People Visited</h2>
                                <div className="ml-auto">
                                <Select defaultValue="days" onChange={handleChange}>
                                    <Option value="days">Last 7 days</Option>
                                    <Option value="month">last one month</Option>
                                </Select>
                                </div>
                               
                            </div>
                            <ReactEcharts option={lineChartOptions} />
                        </div>
                    </div>
                </Col>

            </Row>

        </div>
    )
}

export default Overview;