export interface IBannerReducer {
  totalBannerImages: number;
  hasMore: boolean;
  bannerList: Array<Banner>;
  currentPage: number;
  selectedBanner: Array<Banner>;
}

export interface BannerListResponse {
  banner: Array<Banner>;
  total: number;
  hasMore: boolean;
  currentPage: number;
}

export interface Banner {
  _id: string;
  isActive: boolean;
  url: string;
}

export const buildBannerRequestBody = (data: any): FormData => {
  const formData = new FormData();
  data.forEach((file: any) => {
    formData.append("bannerImg", file.originFileObj);
  });
  return formData;
};
