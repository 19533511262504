import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  Table,
  TablePaginationConfig,
  Button,
  Breadcrumb,
  Input,
  Image,
  Space,
  message,
} from "antd";
import {
  HomeOutlined,
  PlusOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import {
  deleteBlogs,
  getBlogs,
  updateBlog,
} from "../../../store/actions/blogActions/blogActionCreators";
import DeleteBlogModal from "./component/deleteBlog";
import EditBlog from "./component/editBlog";
// import  EditBlog  from './component/editBlog';

interface Pagination {
  current: number;
  pageSize: number;
  total: number;
}

const Blog = () => {
  const blog: any = useSelector<any>((state) => state.blogReducer);
  const dispatch = useDispatch();
  // const [blogIdVisible, setBlogIdVisible] = useState(false)
  const [deleteBlogVisible, setDeleteBlogVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState({ _id: null });
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const pagination: Pagination = {
    current: blog.currentPage,
    pageSize: 10,
    total: blog.totalPage,
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    dispatch(getBlogs(pagination.current as number));
  };

  const selectBlog = (data: any, isEdit: boolean) => {
    if (isEdit) {
      setShowEditModal(true);
    } else {
      setDeleteBlogVisible(true);
    }
    setSelectedRow(data);
  };

  const onDeleteBlogs = (id: any) => {
    dispatch(
      deleteBlogs(id, (res: any, err: any) => {
        setDeleteBlogVisible(false);
        message.success("Sucessfully Deleted");
        dispatch(getBlogs(blog.currentPage));
      })
    );
  };
  const hideBlogModal = () => {
    setDeleteBlogVisible(false);
  };

  const onUpdateBlog = (data: any, id: string) => {
    dispatch(
      updateBlog(data, id, (res: any, err: any) => {
        if (err) {
          console.log(err);
        }
        if (res) {
          setShowEditModal(false);
          message.success("Blog updated successfully.");
          dispatch(getBlogs(blog.currentPage));
        }
      })
    );
  };

  useEffect(() => {
    dispatch(getBlogs(1));
  }, [dispatch]);

  // const dropMenu = (_data: any) => {
  //   return (
  //     <Menu>
  //       <Menu.Item key="0">
  //         <Link to={`blog/${_data._id}`}>
  //           <Button size="small" icon={<EyeOutlined />}>
  //             View
  //           </Button>
  //         </Link>
  //       </Menu.Item>
  //       <Menu.Item key="1">
  //         <Button
  //           type="link"
  //           size="small"
  //           icon={<EditOutlined />}
  //           onClick={selectBlog.bind(null, _data, true)}
  //         >
  //           Edit
  //         </Button>
  //       </Menu.Item>
  //       <Menu.Item key="2">
  //         <Button
  //           type="link"
  //           size="small"
  //           icon={<DeleteOutlined />}
  //           danger
  //           onClick={selectBlog.bind(null, _data, false)}
  //         >
  //           Delete
  //         </Button>
  //       </Menu.Item>
  //     </Menu>
  //   );
  // };

  const columns = [
    {
      title: "S.N",
      dataIndex: "key",
      key: "key",
      render: (key: any) => <span>{key}.</span>,
    },
    {
      title: "title",
      dataIndex: "title",
      key: "title",
    },

    {
      title: "Images",
      key: "url",
      dataIndex: "url",
      render: (url: any) => (
        <div className="d-flex img-wrap">
          {url.map((links: any, index: any) => {
            return <Image key={index} src={links} />;
          })}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_data: any) => {
        return (
          <Space>
            <Link to={`/blog/${_data._id}`}>
              <Button size="small" icon={<EyeOutlined />}></Button>
            </Link>

            <Button
              type="link"
              size="small"
              icon={<EditOutlined />}
              onClick={selectBlog.bind(null, _data, true)}
            ></Button>

            <Button
              type="link"
              size="small"
              icon={<DeleteOutlined />}
              danger
              onClick={selectBlog.bind(null, _data, false)}
            ></Button>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="content-table">
      <Breadcrumb>
        <Breadcrumb.Item href="/home">
          <HomeOutlined />
        </Breadcrumb.Item>

        <Breadcrumb.Item className="active">Blog List</Breadcrumb.Item>
      </Breadcrumb>
      <div className="content-title">
        <h3>Blog Table</h3>
        <p>Blog table show all the blogs added by the user</p>
      </div>
      <div className="content-header d-flex mb-20">
        <Link to="/add-blog">
          <Button type="primary" icon={<PlusOutlined />}>
            Add Blog
          </Button>
        </Link>
        <div className="search-wrap ml-auto">
          <div className="form-group-wrap">
            <Input />
          </div>
        </div>
      </div>

      <div className="card-shadow">
        <Table
          columns={columns}
          dataSource={blog.blogs}
          loading={blog.loadingBlog}
          pagination={pagination}
          onChange={handleTableChange}
        />
        <DeleteBlogModal
          show={deleteBlogVisible}
          hide={hideBlogModal}
          selectedRow={selectedRow}
          onDeleteBlogs={onDeleteBlogs}
        />
        {showEditModal && (
          <EditBlog
            show={true}
            hide={() => setShowEditModal(false)}
            selectedRow={selectedRow}
            onUpdateBlog={onUpdateBlog}
          />
        )}
      </div>
    </div>
  );
};

export default Blog;
