export interface BlogInterface {
  totalPage: number;
  currentPage: number;
  blogs: Array<any>;
  loadingBlog: boolean;
  loadingBlogById: boolean;
  selectedBlog: Object;
  creatingBlog: boolean;
  deletingBlog: boolean;
  editingBlog: boolean;
}

export const buildBlogList = (blogs: Array<any>, currentPage: number) => {
  let blogList = blogs.map((blog, index) => ({
    ...blog,
    key: (currentPage - 1) * 10 + index + 1,
  }));
  return blogList;
};

export const buildBlogRequestBody = (blog: any) => {
  const formData = new FormData();
  formData.append("title", blog.title);
  formData.append("paragraph_1", blog.paragraph_1);
  formData.append("paragraph_2", blog.paragraph_2);
  blog.files.forEach((file: any) => {
    if (file.originFileObj) {
      formData.append("blogImage", file.originFileObj);
    } else {
      formData.append("blogImage", JSON.stringify(file));
    }
  });
  return formData;
};
