import { CategoryReducer } from "../../interfaces/category.interface";
import * as categoryActionTypes from "../actions/categoryActions/categoryActionsTypes";

const initState: CategoryReducer = {
  totalPage: 0,
  categories: [],
  currentPage: 1,
  loadingCategory: false,
  addingCategory: false,
  editingCategory: false,
  deletingCategory: false,
  allCategories: [],
};

export const categoryReducer = (state = initState, action: any) => {
  switch (action.type) {
    case categoryActionTypes.LOADING_CATEGORY:
      return {
        ...state,
        loadingCategory: true,
      };
    case categoryActionTypes.LOADING_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: [...action.categories],
        currentPage: action.currentPage,
        totalPage: action.total,
        loadingCategory: false,
      };
    case categoryActionTypes.LOADING_CATEGORY_ERROR:
      return {
        ...state,
        loadingCategory: false,
      };
    case categoryActionTypes.EDITING_CATEGORY:
      return {
        ...state,
        editingCategory: true,
      };
    case categoryActionTypes.EDITING_CATEGORY_SUCCESS:
      return {
        ...state,
        editingCategory: false,
      };
    case categoryActionTypes.EDITING_CATEGORY_ERROR:
      return {
        ...state,
        editingCategory: true,
      };
    case categoryActionTypes.DELETING_CATEGORY:
      return {
        ...state,
        deletingCategory: true,
      };
    case categoryActionTypes.DELETING_CATEGORY_SUCCESS:
      return {
        ...state,
        deletingCategory: false,
      };
    case categoryActionTypes.DELETING_CATEGORY_ERROR:
      return {
        ...state,
        deletingCategory: false,
      };
    case categoryActionTypes.ADDING_CATEGORY:
      return {
        ...state,
        addingCategory: true,
      };
    case categoryActionTypes.ADDING_CATEGORY_SUCCESS:
      return {
        ...state,
        addingCategory: false,
      };
    case categoryActionTypes.ADDING_CATEGORY_ERROR:
      return {
        ...state,
        addingCategory: false,
      };
    case categoryActionTypes.GETTING_CATEGORY_BY_TITLE_SUCCESS:
      if ((action.title = "all")) {
        return {
          ...state,
          allCategories: action.payload,
        };
      } else {
        return { ...state };
      }

    default:
      return state;
  }
};
