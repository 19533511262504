import { Modal, Form, Select, Upload, message } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import { updatePhotos } from "../../../../../store/actions/GalleyActions/galleryActionCreators";
import { PlusOutlined } from "@ant-design/icons";
import { IHttpError } from "../../../../../interfaces/http.interface";
import { useState } from "react";

interface IProps {
  show: boolean;
  data: any;
  closeModal: () => void;
}

const { Option } = Select;

function EditPhotos(props: IProps) {
  const category: any = useSelector<any>((state) => state.categoryReducer);
  const [form] = Form.useForm();
  const [updating, setUpdating] = useState<boolean>(false);
  const dispatch = useDispatch();

  const initialCategoryList = (props.data && props.data.category) || [];
  const initialIsFeatured = (props.data && props.data.isFeatured) || false;
  const initialUrl = [
    {
      uid: "1",
      name: "image.png",
      status: "done",
      url: (props.data && props.data.url) || null,
    },
  ];
  const editHandler = (values: any) => {
    setUpdating(true);
    dispatch(
      updatePhotos(values, props.data._id, (res: any, err: IHttpError) => {
        setUpdating(false);
        if (res) {
          props.closeModal();
          message.success("Image updated successfully");
        }
        if (err) {
          message.error(
            err.response?.data.message || "Cannot update image at the moment"
          );
        }
      })
    );
  };

  const fileChangeHandler = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Modal
      title="Update Image"
      visible={props.show}
      onCancel={props.closeModal}
      confirmLoading={updating}
      onOk={() => {
        form.submit();
      }}
    >
      <Form
        form={form}
        layout="horizontal"
        name="form_in_modal"
        onFinish={editHandler}
        initialValues={{
          category: initialCategoryList.map((catgory: any) => catgory._id),
          featured: initialIsFeatured,
        }}
      >
        <Form.Item name="category" label="Category">
          <Select
            mode="multiple"
            showSearch
            style={{ width: 500 }}
            placeholder="Select category"
            optionFilterProp="children"
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {category.allCategories.map((cat: any) => (
              <Option key={cat._id} value={cat._id}>
                {cat.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="featured" valuePropName="checked">
          <Checkbox>Featured</Checkbox>
        </Form.Item>
        <Form.Item
          name="file"
          label="Image"
          valuePropName="fileList"
          initialValue={initialUrl}
          getValueFromEvent={fileChangeHandler}
        >
          <Upload
            listType="picture-card"
            beforeUpload={() => false}
            maxCount={1}
            showUploadList={{ showPreviewIcon: false, showRemoveIcon: false }}
          >
            {uploadButton}
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditPhotos;
