import * as blogActionTypes from "./blogActionTypes";
import axios from "../../../api/axios";
import { buildBlogList } from "../../../interfaces/blog.interface";

export const getBlogs = (pageNum: number) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: blogActionTypes.LOADING_BLOG });
      const response = await axios.get(`/blog/${pageNum}`);
      if (response.status === 200) {
        dispatch({
          type: blogActionTypes.LOADING_BLOG_SUCCESS,
          blogs: buildBlogList(response.data.blog, response.data.currentPage),
          currentPage: response.data.currentPage,
          total: response.data.total,
        });
      } else {
        dispatch({
          type: blogActionTypes.LOADING_BLOG_ERROR,
        });
      }
    } catch (err) {
      dispatch({
        type: blogActionTypes.LOADING_BLOG_ERROR,
      });
    }
  };
};

export const updateBlog = (data: any, id: string, cb: Function) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: blogActionTypes.EDITING_BLOG });
      const response = await axios.patch(`/blog/detail/${id}`, data);
      if (response.status === 200) {
        dispatch({
          type: blogActionTypes.EDITING_BLOG_SUCCESS,
          data: response.data,
        });
        cb(true, false);
      } else {
        dispatch({ type: blogActionTypes.EDITING_BLOG_ERROR });
        cb(false, true);
      }
    } catch (err) {
      dispatch({ type: blogActionTypes.EDITING_BLOG_ERROR });
      cb(null, err);
    }
  };
};

export const getBlogById = (id: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: blogActionTypes.LOADING_BLOGBYID,
      });
      const response = await axios.get(`/blog/detail/${id}`);
      if (response.status === 200) {
        dispatch({
          type: blogActionTypes.LOADING_BLOGBYID_SUCCESS,
          blogDetail: response.data,
        });
      } else {
        dispatch({
          type: blogActionTypes.LOADING_BLOGBYID_ERROR,
        });
      }
    } catch (err) {
      dispatch({
        type: blogActionTypes.LOADING_BLOGBYID_ERROR,
      });
    }
  };
};

export const createBlog = (data: any, cb: Function) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: blogActionTypes.CREATING_BLOG });
      const response = await axios.post("/blog/add", data);
      if (response.status === 200) {
        dispatch({ type: blogActionTypes.CREATING_BLOG_SUCCESS });
        cb(true, null);
      } else {
        dispatch({ type: blogActionTypes.CREATING_BLOG_ERROR });
        cb(null, true);
      }
    } catch (err) {
      dispatch({ type: blogActionTypes.CREATING_BLOG_ERROR });
      cb(null, err);
    }
  };
};

export const deleteBlogs = (id: any, cb: Function) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: blogActionTypes.DELETE_BLOG,
      });
      const response = await axios.delete(`/blog/detail/${id}`);
      if (response.status === 200) {
        dispatch({
          type: blogActionTypes.DELETE_BLOG_SUCCESS,
        });
        cb(true, null);
      } else {
        dispatch({
          type: blogActionTypes.DELETE_BLOG_ERROR,
        });
        cb(false, true);
      }
    } catch (err) {
      dispatch({
        type: blogActionTypes.DELETE_BLOG_ERROR,
      });
      cb(false, err);
    }
  };
};
