import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Modal, Input, Upload, Form, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { IHttpError } from "../../../../../interfaces/http.interface";
import {
  editVideos,
  getAllVideos,
} from "../../../../../store/actions/GalleyActions/galleryActionCreators";
import { buildUpdateVideoRequestBody } from "../../../../../interfaces/gallery.interface";
// import { buildVideoRequestBody } from '../../../../../interfaces/gallery.interface';

interface IProps {
  show: boolean;
  hide: () => void;
  selectedRow: any;
  onUpdateVideos: (data: any) => void;
}

const EditVideos = (props: IProps) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  // const video: any = useSelector<any>((state) => state.galleryReducer);
  const [updating, setUpdating] = useState<boolean>(false);
  const initialVideoTitle =
    (props.selectedRow && props.selectedRow.title) || [];
  const initialVideoUrl = (props.selectedRow && props.selectedRow.url) || [];
  const initialthumbnailImg = [
    {
      uid: "1",
      name: "image.png",
      status: "done",
      url: (props.selectedRow && props.selectedRow.thumbnail) || null,
    },
  ];
  const fileChangeHandler = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const editHandler = (values: any) => {
    setUpdating(true);
    dispatch(
      editVideos(values, props.selectedRow._id, (res: any, err: IHttpError) => {
        setUpdating(false);
        if (res) {
          props.hide();
          message.success("Video updated successfully");
          dispatch(getAllVideos(1));
        }
        if (err) {
          message.error(
            err.response?.data.message || "Cannot update Video at the moment"
          );
        }
      })
    );
  };

  const urlValidator = (rule: any, value: any) => {
    return new Promise((resolve: any, reject: any) => {
      if (value.length === 0) {
        reject("Video url is required");
      }
      if (
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w-]+)+[\w\-_~:/?#[\]@!&',;=.]+$/.test(
          value
        )
      ) {
        resolve();
      }
      reject("Link is not in correct format");
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  useEffect(() => {
    // setTitle(props.selectedRow && props.selectedRow.title)
    // setTags(props.selectedRow && props.selectedRow.tags)
  }, [props]);

  return (
    <Modal
      title="Edit Video"
      visible={props.show}
      onCancel={props.hide}
      confirmLoading={updating}
      onOk={() => {
        form.submit();
      }}
    >
      <div className="form-wraps">
        <Form
          form={form}
          name="form_in_modal"
          onFinish={editHandler}
          initialValues={{
            title: initialVideoTitle,
            url: initialVideoUrl,
          }}
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[
              { required: true, message: "Title is required" },
              {
                min: 4,
                message: "Title must be of atleast 4 character long",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="url"
            label="Url"
            rules={[{ required: true }, { validator: urlValidator }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
          name="thumbnailImg"
          label="Thumbnail Image"
          valuePropName="fileList"
          initialValue={initialthumbnailImg}
          getValueFromEvent={fileChangeHandler}
        >
          <Upload
            listType="picture-card"
            beforeUpload={() => false}
            maxCount={1}
            showUploadList={{ showPreviewIcon: false, showRemoveIcon: false }}
          >
            {uploadButton}
          </Upload>
        </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
export default EditVideos;
