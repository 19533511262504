import { useCallback, useEffect, useState } from "react";
import { Breadcrumb, Row, Col, Button } from "antd";
import {
  HomeOutlined,
  EditOutlined,
  DeleteOutlined,
  FundViewOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { getAllPhotos } from "../../../../store/actions/GalleyActions/galleryActionCreators";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { getCategoryByTitle } from "../../../../store/actions/categoryActions/categoryActionCreators";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import {
  GallerySkeleton,
  CategoryChipsSkeletion,
} from "../../../../common/components/skeleton";
import EditPhotosModal from "./components/editPhotos";
import DeletePhotosModal from "./components/deletePhotos";
import PreviewImage from "../../../../common/components/previewImage";
import _ from "lodash";

const scrollOffset = 20;

const Photos = () => {
  const gallery: any = useSelector<any>((state) => state.galleryReducer);
  const category: any = useSelector<any>((state) => state.categoryReducer);
  const dispatch = useDispatch();
  const { title }: any = useParams();
  const [categoryLoader, setCategoryLoader] = useState<boolean>(false);
  const [initiaLoader, setInitiaLoader] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<null | JSX.Element>(null);
  const [showPreviewModal, setShowPreviewModal] =
    useState<null | JSX.Element>(null);
  const [showDeleteModal, setShowDeleteModal] =
    useState<null | JSX.Element>(null);
  const [multiSelectIds, setMultiSelectIds] = useState<Array<string>>([]);

  const checkScrollAndCallApi = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.scrollHeight - scrollOffset
    ) {
      if (gallery.totalPages >= gallery.currentPage) {
        if (gallery.photosLoading) {
          return;
        }
        dispatch(
          getAllPhotos(gallery.currentPage, title, (res: any, err: any) => {
            if (err) {
              console.log(err);
            }
          })
        );
      }
    }
  }, [
    gallery.currentPage,
    gallery.totalPages,
    dispatch,
    gallery.photosLoading,
    title,
  ]);

  const editImageHandler = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    imgDetail: any
  ) => {
    e.stopPropagation();
    setShowEditModal(
      <EditPhotosModal
        show={true}
        closeModal={closeEditModal}
        data={imgDetail}
      />
    );
  };

  const closeEditModal = () => {
    setShowEditModal(null);
  };

  const handleInfiniteScroll = useCallback(() => {
    window.addEventListener("scroll", checkScrollAndCallApi);
  }, [checkScrollAndCallApi]);

  useEffect(() => {
    handleInfiniteScroll();
    return () => {
      window.removeEventListener("scroll", checkScrollAndCallApi);
    };
  }, [handleInfiniteScroll, checkScrollAndCallApi]);

  const deleteImageHandler = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    id: string
  ) => {
    e.stopPropagation();
    setShowDeleteModal(
      <DeletePhotosModal show={true} closeModal={closeDeleteModal} id={[id]} />
    );
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(null);
  };

  const previewHandler = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    url: string
  ) => {
    e.stopPropagation();
    setShowPreviewModal(
      <PreviewImage
        show={true}
        url={url}
        closeModal={() => setShowPreviewModal(null)}
      />
    );
  };

  const multiDeleteHandler = (id: string) => {
    let deleteListIDs = [...multiSelectIds];
    if (_.includes(multiSelectIds, id)) {
      _.remove(deleteListIDs, (n: string) => n === id);
      setMultiSelectIds(deleteListIDs);
    } else {
      deleteListIDs.push(id);
      setMultiSelectIds([...deleteListIDs]);
    }
  };

  const deleteMultipleDelete = () => {
    setShowDeleteModal(
      <DeletePhotosModal
        show={true}
        closeModal={closeDeleteModal}
        id={multiSelectIds}
      />
    );
  };

  useEffect(() => {
    setCategoryLoader(true);
    dispatch(
      getCategoryByTitle("all", (res: boolean, err: any) => {
        setCategoryLoader(false);
        if (err) {
          console.log(err);
        }
      })
    );
  }, [dispatch, category.allCategories.length]);

  useEffect(() => {
    setInitiaLoader(true);
    dispatch(
      getAllPhotos(1, title, (res: any, err: any) => {
        setInitiaLoader(false);
        if (err) {
          console.log(err);
        }
      })
    );
  }, [dispatch, title]);

  return (
    <div className="main-contents">
      {showEditModal}
      {showDeleteModal}
      {showPreviewModal}
      <Breadcrumb>
        <Breadcrumb.Item href="/home">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item className="active">
          <span>Photos Collection</span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="content-title">
        <div className="d-flex">
          <h3>Photos Collection</h3>
          <div className="ml-auto">
            {multiSelectIds.length > 0 && (
              <Button
                icon={<DeleteOutlined />}
                size="small"
                type="primary"
                danger
                className="w-auto"
                onClick={() => deleteMultipleDelete()}
              >
                Delete
              </Button>
            )}
          </div>
        </div>

        <div className="carousel-container">
          {categoryLoader && <CategoryChipsSkeletion />}
          {!categoryLoader && (
            <NavLink to="all" className="carousel-item">
              All
            </NavLink>
          )}
          {!categoryLoader &&
            category.allCategories.map((catgory: any) => (
              <NavLink
                key={catgory._id}
                to={`${catgory.title}`}
                className="carousel-item"
              >
                {catgory.title}
              </NavLink>
            ))}
        </div>
      </div>
      <div className="content-add-form">
        <Row gutter={[16, 16]}>
          {!initiaLoader &&
            gallery.photos &&
            gallery.photos.map((pic: any) => (
              <Col span={6} key={pic._id}>
                <div className="photos-wrap ">
                  <div
                    className={
                      _.includes(multiSelectIds, pic._id)
                        ? "img-wrap selected"
                        : "img-wrap"
                    }
                    onClick={(e) => multiDeleteHandler(pic._id)}
                  >
                    <LazyLoadImage
                      alt="Photos"
                      src={pic.url}
                      effect="blur"
                      className="img-fluid"
                      key={pic._id}
                    />
                    <div className="photo-wrap-overlay">
                      <div className="d-flex">
                        <Button
                          className="preview"
                          onClick={(e) => previewHandler(e, pic.url)}
                        >
                          <FundViewOutlined />
                        </Button>
                        <Button
                          className="delete"
                          onClick={(e) => deleteImageHandler(e, pic._id)}
                        >
                          <DeleteOutlined />
                        </Button>
                        <Button
                          className="edit"
                          onClick={(e) => editImageHandler(e, pic)}
                        >
                          <EditOutlined />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="categories-listing mt-10">
                    <h5>Category</h5>
                    <p>
                      {pic.category.map((cat: any) => (
                        <span key={cat._id}>{cat.title}</span>
                      ))}
                    </p>
                  </div>
                </div>
              </Col>
            ))}
          {gallery.photosLoading && <GallerySkeleton />}
        </Row>
      </div>
    </div>
  );
};

export default Photos;
