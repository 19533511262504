import { Button, Col, message, Row, Typography, Image } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GallerySkeleton } from "../../../common/components/skeleton";
import { Banner, IBannerReducer } from "../../../interfaces/banner.interface";
import { IHttpError } from "../../../interfaces/http.interface";
import placeholderImg from "../../../assets/images/placeholderImg.jpg";
import {
  getBannerImages,
  updateBanner,
} from "../../../store/actions/bannerActions/bannerActionCreators";
import { DeleteOutlined, FundViewOutlined } from "@ant-design/icons";
import PreviewImage from "../../../common/components/previewImage";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { apiError } from "../../../common/helpers/common";
import DeleteBannerModal from "./DeleteBannerModal";

function AllBannerImage() {
  const banner: IBannerReducer = useSelector<any, any>(
    (state) => state.bannerReducer
  );
  const dispatch = useDispatch();
  const [loadingBanner, setLoadingBanner] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<null | JSX.Element>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<null | JSX.Element>(
    null
  );
  const [settingBanner, setSettingBanner] = useState<boolean>(false);

  const infiniteScrollHandler = (element: any) => {
    if (
      element.target.scrollHeight - element.target.scrollTop <=
      element.target.clientHeight
    ) {
      if (loadingBanner) {
        return;
      }
      if (banner.hasMore) {
        setLoadingBanner(true);
        dispatch(
          getBannerImages(
            banner.currentPage + 1,
            (res: boolean, err: IHttpError) => {
              setLoadingBanner(false);
              if (err) {
                console.log(err);
              }
            }
          )
        );
      }
    }
  };

  const previewHandler = (url: string) => {
    setShowPreview(
      <PreviewImage
        show={true}
        url={url}
        closeModal={() => setShowPreview(null)}
      />
    );
  };

  const selectImageHandler = (id: string, e: any) => {
    if (settingBanner) {
      return;
    }
    setSettingBanner(true);
    dispatch(
      updateBanner(id, e.target.checked, (res: boolean, err: IHttpError) => {
        setSettingBanner(false);
        if (res) {
          message.success(
            `Banner image ${e.target.checked ? "selected" : `unselected`
            } successfully.`
          );
        }
        if (err) {
          apiError(err);
        }
      })
    );
  };

  const openDeleteModal = (id: string) => {
    setShowDeleteModal(
      <DeleteBannerModal
        closeModal={() => setShowDeleteModal(null)}
        show={true}
        id={id}
      />
    );
  };

  useEffect(() => {
    if (banner.bannerList.length === 0) {
      dispatch(
        getBannerImages(1, (res: boolean, err: IHttpError) => {
          if (err) {
            console.log(err);
          }
        })
      );
    }
  }, [dispatch, banner.bannerList.length]);

  return (
    <>
      {showPreview}
      {showDeleteModal}
      <div className="banner-wrapper" >
        <div className="header">
          <Typography.Title level={5}>All Images</Typography.Title>
        </div>
        <div className="banner-scroll-container" onScroll={infiniteScrollHandler}>
          <Row gutter={15}>
            {banner.bannerList.map((banner: Banner) => (
              <Col span={8} key={banner._id}>
                <div className="banner-photos-wrap">
                  <Checkbox
                    checked={banner.isActive}
                    onChange={selectImageHandler.bind(null, banner._id)}
                    className="check-box"
                  />
                  <div className="select-upload-img">
                    <Image
                      alt="Photos"
                      src={banner.url}
                      fallback={placeholderImg}
                      preview={false}
                    />
                    <div className="photo-wrap-overlay">
                      <div className="d-flex">
                        <Button
                          className="preview"
                          onClick={previewHandler.bind(null, banner.url)}
                        >
                          <FundViewOutlined />
                        </Button>
                        <Button
                          className="delete"
                          onClick={openDeleteModal.bind(null, banner._id)}
                        >
                          <DeleteOutlined />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          {banner.hasMore && (
            <div className="d-flex">
              <GallerySkeleton />
            </div>
          )}
        </div>

      </div>
    </>
  );
}

export default AllBannerImage;
