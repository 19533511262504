import { bannerReducer } from "./bannerReducer";
import { authReducer } from "./authReducer";
import { combineReducers } from "redux";
import { categoryReducer } from "./categoryReducers";
import { blogReducer } from "./blogReducer";
import { galleryReducer } from "./galleryReducers";

export const rootReducer = combineReducers({
  categoryReducer,
  blogReducer,
  authReducer,
  galleryReducer,
  bannerReducer,
});
