import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Table,
  Tag,
  Space,
  TablePaginationConfig,
  Button,
  Breadcrumb,
  Input,
  message,
  Image,
} from "antd";
import {
  HomeOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import placeholderImg from "../../../assets/images/placeholderImg.jpg";

import EditCategory from "./components/editcategory";
import DeleteCategory from "./components/deleteCategory";

import {
  getCategories,
  editCategories,
  deleteCategories,
} from "../../../store/actions/categoryActions/categoryActionCreators";
import { Link } from "react-router-dom";
import { buildCategoryFormData } from "../../../interfaces/category.interface";
import { IHttpError } from "../../../interfaces/http.interface";
import { apiError } from "../../../common/helpers/common";

interface Pagination {
  current: number;
  pageSize: number;
  total: number;
}

const CategoryTable = () => {
  const category: any = useSelector<any>((state) => state.categoryReducer);
  const dispatch = useDispatch();
  const [editCategoryVisible, setEditCategoryVisible] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState<null | JSX.Element>(
    null
  );
  const [selectedRow, setSelectedRow] = useState({ _id: null });
  const [selectedMultiRow, setSelectedMultiRow] = useState<Array<any>>([]);

  const editCategory = (text: any) => {
    setEditCategoryVisible(true);
    setSelectedRow(text);
  };

  const deleteSingleCategory = (row: any) => {
    setDeleteCategory(
      <DeleteCategory
        show={true}
        hide={hideCategoryModal}
        selectedRow={[{ ...row }]}
        onDeleteCategories={onDeleteCategories}
        multiple={false}
      />
    );
  };

  const deleteMultipleCategory = () => {
    setDeleteCategory(
      <DeleteCategory
        show={true}
        hide={hideCategoryModal}
        selectedRow={selectedMultiRow}
        onDeleteCategories={onDeleteCategories}
        multiple={true}
      />
    );
  };

  const hideCategoryModal = () => {
    setEditCategoryVisible(false);
    setDeleteCategory(null);
  };

  const onUpdateCategories = (data: any) => {
    const payload = buildCategoryFormData({
      title: data.title,
      tags: data.tags,
      thumbnailImg:
        (data.fileList && data.fileList[0] && data.fileList[0].originFileObj) ||
        null,
    });
    dispatch(
      editCategories(
        payload,
        selectedRow && selectedRow?._id,
        (res: any, err: any) => {
          setEditCategoryVisible(false);
          message.success("Sucessfully Updated");
          dispatch(getCategories(category.currentPage));
        }
      )
    );
  };

  const onDeleteCategories = (data: any, multiple: boolean) => {
    const ids = data.map((data: any) => data._id);
    dispatch(
      deleteCategories(ids, (res: boolean, err: IHttpError) => {
        if (res) {
          setDeleteCategory(null);
          multiple && setSelectedMultiRow([]);
          message.success("Sucessfully Deleted");
          dispatch(getCategories(category.currentPage));
        }
        if (err) {
          apiError(err);
        }
      })
    );
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelectedMultiRow(selectedRows);
    },
  };

  const pagination: Pagination = {
    current: category.currentPage,
    pageSize: 10,
    total: category.totalPage,
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    dispatch(getCategories(pagination.current as number));
  };

  useEffect(() => {
    dispatch(getCategories(1));
  }, [dispatch]);

  const columns = [
    {
      title: "S.N",
      dataIndex: "key",
      key: "key",
      render: (key: any) => <span>{key}.</span>,
    },
    {
      title: "title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnailUrl",
      render: (thumbnailUrl: string) => (
        <Image
          src={thumbnailUrl || placeholderImg}
          fallback={placeholderImg}
          height={100}
          width={100}
          alt="Photos"
        />
      ),
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      render: (tags: any) => (
        <span>
          {tags.map((tag: any) => {
            let color = tag.length > 5 ? "geekblue" : "green";
            if (tag === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_text: any) => {
        return (
          <Space size="middle">
            <Button
              type="link"
              size="small"
              className="actions"
              onClick={editCategory.bind(null, _text)}
            >
              <EditOutlined />
            </Button>
            <Button
              type="link"
              size="small"
              className="actions"
              danger
              onClick={() => deleteSingleCategory(_text)}
            >
              <DeleteOutlined />
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="content-table">
      <Breadcrumb>
        <Breadcrumb.Item href="/home">
          <HomeOutlined />
        </Breadcrumb.Item>

        <Breadcrumb.Item className="active">Category List</Breadcrumb.Item>
      </Breadcrumb>
      <div className="content-title">
        <h3>Category Table</h3>
        <p>Category table show all the categories added by the user</p>
      </div>
      <div className="content-header d-flex mb-20">
        <Link to="/add-category">
          <Button type="primary" icon={<PlusOutlined />}>
            Add Category
          </Button>
        </Link>
        {selectedMultiRow.length > 0 && category.categories.length > 0 && (
          <Button
            style={{ marginLeft: "0.7rem" }}
            icon={<DeleteOutlined />}
            type="primary"
            danger
            onClick={() => deleteMultipleCategory()}
          >
            Delete
          </Button>
        )}
        <div className="search-wrap ml-auto">
          <div className="form-group-wrap">
            <Input />
          </div>
        </div>
      </div>
      <div className="card-shadow">
        <Table
          columns={columns}
          dataSource={category.categories}
          loading={category.loadingCategory}
          pagination={pagination}
          onChange={handleTableChange}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
      </div>
      {deleteCategory}
      {editCategoryVisible && (
        <EditCategory
          show={true}
          hide={hideCategoryModal}
          selectedRow={selectedRow}
          onUpdateCategories={onUpdateCategories}
        />
      )}
    </div>
  );
};

export default CategoryTable;
