import { Upload, message, Button, Form } from "antd";
import React, { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { buildBannerRequestBody } from "../../../interfaces/banner.interface";
import { useDispatch } from "react-redux";
import { addBanner } from "../../../store/actions/bannerActions/bannerActionCreators";
import { IHttpError } from "../../../interfaces/http.interface";
import { apiError } from "../../../common/helpers/common";

const { Dragger } = Upload;

function UploadComponent() {
  const [fileList, setFileList] = useState<Array<File>>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const props = {
    name: "file",
    multiple: true,
    beforeUpload: () => false,
    showUploadList: { showPreviewIcon: false },
    onChange(info: any) {
      setFileList(info.fileList);
    },
  };

  const uploadBannerHandler = () => {
    if (fileList.length > 0) {
      setUploading(true);
      const data = buildBannerRequestBody(fileList);
      dispatch(
        addBanner(data, (res: boolean, err: IHttpError) => {
          setUploading(false);
          if (err) {
            apiError(err);
          }
          if (res) {
            setFileList([]);
            message.success("Images uploaded successfully");
            form.resetFields();
          }
        })
      );
    }
  };

  return (
    <div className="banner-wrappers">
      {/* <h3 className="heading text-center">Upload Banner Images</h3> */}
      <div className="banner-body">
        <Form form={form} name="control-hooks" layout="vertical">
          <Form.Item name="file">
            <Dragger {...props} listType="picture-card" className="mb-2">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                {" "}
                Support for a single or bulk upload. Strictly prohibit from
                uploading company data or otherband files
              </p>
            </Dragger>
          </Form.Item>
        </Form>
      </div>
      <div className="d-flex upload-btn">
        <Button
          type="primary"
          onClick={uploadBannerHandler}
          loading={uploading}
        >
          Upload
        </Button>
      </div>
    </div>
  );
}

export default UploadComponent;
