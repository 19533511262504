import React, { useState } from "react";
import { Form, Input, Row, Col, Button, message } from "antd";
import { useDispatch } from "react-redux";
import { authenticate } from "../../store/actions/authActions/authActionCreators";
import { Redirect, useHistory } from "react-router";
// import { checkAuth } from "../../store/actions/authActions/authActionCreators";
import { checkAuthStatus } from "../../util/auth.util";
import { LOADING_BANNER_SUCCESS } from "../../store/actions/bannerActions/bannerActionTypes";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 24 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      span: 24,
    },
  },
};

const Login = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState<boolean>(false)
  // const token = useSelector<any>((state) => state.authReducer.token);

  // useEffect(() => {
  //   dispatch(checkAuth());
  // }, [dispatch]);

  const authHandler = (values: any) => {
    setLoader(true)
    dispatch(
      authenticate(values, (res: any, err: any) => {
        setLoader(false)
        if (err) {
          message.error("Inavlid Username and Password !!!");
        }
        if (res) {
          history.push("/");
        }
      })
    );
  };

  if (checkAuthStatus()) {
    return <Redirect to="/" />;
  }

  return (
    <div className="container">
      <div className="form-card">
        <Row>
          <Col span={10} className="m-auto">
            <div className="form-card-inner">
              <div className="heading">
                <h2 className="text-center">Login</h2>
              </div>
              <Form
                {...formItemLayout}
                form={form}
                name="signin"
                onFinish={authHandler}
                scrollToFirstError
              >
                <Form.Item
                  name="username"
                  label="Username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item {...tailFormItemLayout} className="mt-3">
                  <Button type="primary" htmlType="submit" loading={loader}>
                    Sign in
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
