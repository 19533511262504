import { BlogInterface } from "../../interfaces/blog.interface";
import * as BlogActionTypes from "../actions/blogActions/blogActionTypes";

const initState: BlogInterface = {
  totalPage: 0,
  blogs: [],
  currentPage: 1,
  loadingBlog: false,
  loadingBlogById: false,
  creatingBlog: false,
  selectedBlog: {},
  deletingBlog: false,
  editingBlog: false,
};
export const blogReducer = (state = initState, action: any) => {
  switch (action.type) {
    case BlogActionTypes.LOADING_BLOG:
      return {
        ...state,
        loadingBlog: true,
      };
    case BlogActionTypes.LOADING_BLOG_SUCCESS:
      return {
        ...state,
        blogs: [...action.blogs],
        currentPage: action.currentPage,
        totalPage: action.total,
        loadingBlog: false,
      };
    case BlogActionTypes.LOADING_BLOG_ERROR:
      return {
        ...state,
        loadingBlog: false,
      };
    case BlogActionTypes.LOADING_BLOGBYID:
      return {
        ...state,
        loadingBlogById: true,
      };
    case BlogActionTypes.LOADING_BLOGBYID_SUCCESS:
      return {
        ...state,
        selectedBlog: { ...action.blogDetail },
        loadingBlogById: false,
      };
    case BlogActionTypes.LOADING_BLOGBYID_ERROR:
      return {
        ...state,
        loadingBlogById: false,
      };
    case BlogActionTypes.CREATING_BLOG:
      return {
        ...state,
        creatingBlog: true,
      };
    case BlogActionTypes.CREATING_BLOG_SUCCESS:
      return {
        ...state,
        creatingBlog: false,
      };
    case BlogActionTypes.CREATING_BLOG_ERROR:
      return {
        ...state,
        creatingBlog: false,
      };
    case BlogActionTypes.DELETE_BLOG:
      return {
        ...state,
        deletingBlog: true,
      };
    case BlogActionTypes.DELETE_BLOG_SUCCESS:
      return {
        ...state,
        deletingBlog: false,
      };
    case BlogActionTypes.DELETE_BLOG_ERROR:
      return {
        ...state,
        deletingBlog: false,
      };
    case BlogActionTypes.EDITING_BLOG:
      return {
        ...state,
        editingBlog: true,
      };
    case BlogActionTypes.EDITING_BLOG_SUCCESS:
      return {
        ...state,
        editingBlog: false,
      };
    case BlogActionTypes.EDITING_BLOG_ERROR:
      return {
        ...state,
        editingBlog: false,
      };
    default:
      return state;
  }
};
