import React, { useState } from "react";
import {
  Breadcrumb,
  Select,
  Input,
  Button,
  message,
  Typography,
  Upload,
  Form,
  Modal
} from "antd";
import { HomeOutlined, TableOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { addCategories } from "../../../../store/actions/categoryActions/categoryActionCreators";
import { buildCategoryFormData } from "../../../../interfaces/category.interface";
import { NavLink } from "react-router-dom";
import { apiError } from "../../../../common/helpers/common";
import { IHttpError } from "../../../../interfaces/http.interface";

const { Text } = Typography;

const AddCategory = () => {
  const category: any = useSelector<any>((state) => state.categoryReducer);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [title, setTitle] = useState("");
  const [tags, setTags] = useState([]);
  const [titleError, setTitleError] = useState<any>(false);
  const [thumbnailImg, setThumbnailImg] = useState<any>({});
  const [previewImage, setPreviewImage] = useState<any>();
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);

  const titleHandler = (e: any) => {
    setTitle(e.target.value);
    setTitleError(false);
  };
  const handleTagChange = (value: any) => {
    setTags(value);
  };

  const submitCategory = () => {
    if (title.length === 0) {
      setTitleError("Title is required");
      return;
    }
    if (title.length >= 3) {
      setTitleError(false);
      const data = buildCategoryFormData({ title, tags, thumbnailImg });
      dispatch(
        addCategories(data, (res: boolean, err: IHttpError) => {
          if (res) {
            setTitle("");
            setTags([]);
            setThumbnailImg(null);
            form.resetFields();
            message.success("Category Sucessfully added");
          }
          if (err) {
            apiError(err);
            // message.error("category must be unique");
          }
        })
      );
    } else {
      setTitleError("Title must be altleast three character");
    }
  };

  const getBase64 =(file:any) =>{
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

 const  handleCancel = () => {
  setPreviewVisible(false)

 };
 const  handlePreview = async (file:any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview,)
    setPreviewVisible(true)
  };

  const thumbnailChangeHandler = (e: any) => {
    setThumbnailImg(e.file);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div className="main-contents">
      <Breadcrumb>
        <Breadcrumb.Item href="/home">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <TableOutlined />
          <NavLink to="/category">
            <span>Category List</span>
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="active">Add Category</Breadcrumb.Item>
      </Breadcrumb>
      <div className="content-title">
        <h3>Category</h3>
        <p>Add category of images for the gallery</p>
      </div>
      <div className="content-add-form">
        <div className="form-wraps">
          <div className="form-group">
            <label> Title: </label>
            <Input
              value={title}
              onChange={titleHandler}
              className={titleError ? "control-error" : ""}
            />
            {titleError && <Text type="danger">{titleError}</Text>}
          </div>
          <div className="form-group">
            <label> Tags: </label>
            <Select
              mode="tags"
              onChange={handleTagChange}
              className="form-control"
              value={tags}
            />
          </div>
          <Form form={form} layout='vertical'>
            <Form.Item label="Thumbnail Image" name="thumbnailUrl">
              <Upload
                name="thumbnailUrl"
                maxCount={1}
                listType="picture-card"
                beforeUpload={() => false}
                onChange={thumbnailChangeHandler}
                onPreview={handlePreview}
              >
                {uploadButton}
              </Upload>
            </Form.Item>
          </Form>

          <div className="form-group">
            <Button
              onClick={submitCategory}
              loading={category.addingCategory}
              type="primary"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
      <Modal
          visible={previewVisible}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
    </div>
  );
};

export default AddCategory;
