import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { useParams, } from 'react-router-dom';

import { Breadcrumb } from "antd";
import { HomeOutlined } from '@ant-design/icons';


import { getBlogById } from "../../../../store/actions/blogActions/blogActionCreators";


const ViewBlogById = () => {
    const blog: any = useSelector<any>((state) => state.blogReducer.selectedBlog);

    const { id } = useParams<any>();
    const dispatch = useDispatch();
    
    console.log(blog)
    useEffect(() => {
        dispatch(getBlogById(id));
    }, [dispatch, id]);
   
    return (
        <div className="content-table">
            <Breadcrumb>
                <Breadcrumb.Item href="/home">
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item href="/blog">
                    {/* <TableOutlined /> */}
                    <span>Blog List</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item className="active">{blog.title}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content-title">
                <h3>View Blog</h3>
                <p>Blog according to {blog.title}</p>
            </div>
        
            <div className="card-shadow">
               <div className="card-view">
                   <table>
                       <tr>
                           <th>Title</th>
                           <td>{blog.title}</td>
                       </tr>
                       <tr>
                           <th>Paragraph_one</th>
                           <td>{blog.paragraph_1}</td>
                       </tr>
                       <tr>
                           <th>Paragraph_two</th>
                           <td>{blog.paragraph_2}</td>
                       </tr>
                       <tr>
                           <th>Img</th>
                           <td>Paragraph_two</td>
                       </tr>
                   </table>
               </div>
            </div>

        </div>
    )
}

export default ViewBlogById;