export const LOADING_BANNER_SUCCESS = "LOADING_BANNER_SUCCESS";

export const GET_SELECTED_BANNER_SUCCESS = "GET_SELECTED_BANNER_SUCCESS";

export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS";

export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS";

export const ADD_BANNER_SUCCESS = "ADD_BANNER_SUCCESS";


export const OVERVIEW_TOTAL_SUCCESS = "OVERVIEW_TOTAL_SUCCESS";
