import {
  Banner,
  BannerListResponse,
} from "./../../../interfaces/banner.interface";
import { IHttpRes } from "./../../../interfaces/http.interface";
import { Dispatch } from "redux";
import * as bannerActionTypes from "./bannerActionTypes";
import axiosInstance from "../../../api/axios";

export const getBannerImages = (page: number, cb: Function) => {
  return async (dispatch: Dispatch) => {
    try {
      const response: IHttpRes<BannerListResponse> = await axiosInstance.get(
        `/banner/${page}`
      );
      if (response) {
        dispatch({
          type: bannerActionTypes.LOADING_BANNER_SUCCESS,
          payload: response.data,
          page: page,
        });
      }
      cb(true, false);
    } catch (err) {
      cb(null, err);
    }
  };
};

export const getSelectedBannerImages = (cb: Function) => {
  return async (dispatch: Dispatch) => {
    try {
      const response: IHttpRes<Array<Banner>> = await axiosInstance.get(
        "/banner/banner-images/active"
      );
      dispatch({
        type: bannerActionTypes.GET_SELECTED_BANNER_SUCCESS,
        payload: response.data,
      });
      cb(true, false);
    } catch (err) {
      cb(false, err);
    }
  };
};

export const updateBanner = (id: string, checked: boolean, cb: Function) => {
  return async (dispatch: Dispatch) => {
    try {
      const response: IHttpRes<any> = await axiosInstance.patch(
        `/banner/update-img/${id}`,
        { is_active: checked }
      );
      if (response) {
        dispatch({
          type: bannerActionTypes.UPDATE_BANNER_SUCCESS,
          id: id,
          isActive: checked,
          url: response.data.url,
        });
      }
      cb(true, false);
    } catch (err) {
      cb(false, err);
    }
  };
};

export const deleteBanner = (id: string, cb: Function) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axiosInstance.delete(`/banner/${id}`);
      if (response) {
        dispatch({ type: bannerActionTypes.DELETE_BANNER_SUCCESS, id: id });
      }
      cb(true, false);
    } catch (err) {
      cb(false, err);
    }
  };
};

export const addBanner = (data: FormData, cb: Function) => {
  return async (dispatch: Dispatch) => {
    try {
      const response: IHttpRes<Banner> = await axiosInstance.post(
        `/banner/add`,
        data
      );
      if (response) {
        dispatch({
          type: bannerActionTypes.ADD_BANNER_SUCCESS,
          payload: response.data,
        });
      }
      cb(true, false);
    } catch (err) {
      cb(false, err);
    }
  };
};


export const getTotalOverview = (cb: Function) => {
  return async (dispatch: Dispatch) => {
    try {
      const response= await axiosInstance.get(
        `/app-statics/totals`
      );
      if (response) {
        dispatch({
          type: bannerActionTypes.OVERVIEW_TOTAL_SUCCESS,
          payload: response.data,
        });
      }
      cb(response.data, false);
    } catch (err) {
      cb(null, err);
    }
  };
};
