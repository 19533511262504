
import React, { useState, useEffect } from 'react'
import { Modal } from 'antd';
import { useSelector } from 'react-redux';

interface IProps {
  show: boolean;
  hide: () => void;
  selectedRow: any;
  onDeleteBlogs: (id: any) => void;
}

const DeleteBlogModal = (props: IProps) => {
  const blog: any = useSelector<any>((state) => state.blogReducer);
  const [blogData, setBlogData] = useState<any>({});

  useEffect(() => {
    setBlogData(props.selectedRow && props.selectedRow);

  }, [props])
  
  return (
    <Modal title={`Delete ${blogData.title}`} visible={props.show} onOk={props.onDeleteBlogs.bind(null, blogData._id)} onCancel={props.hide} confirmLoading={blog.deletingBlogs}>
      <div className="modal-body">
          <p>Do you really want to delete <span className="bold">{blogData.title} ?</span></p>
      </div>
    </Modal>
  )
}
export default DeleteBlogModal;