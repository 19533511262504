import { Col, Skeleton } from "antd";

function GallerySkeleton() {
  return (
    <>
      {new Array(4).fill(1).map((_, index: number) => (
        <Col key={index} span={6}>
          <Skeleton active paragraph={{ rows: 4 }} />
        </Col>
      ))}
    </>
  );
}

function CategoryChipsSkeletion() {
  return (
    <>
      {new Array(7).fill(1).map((_, index: number) => (
        <Skeleton.Button
          style={{ marginRight: "1.5rem", width: 150 }}
          active
          key={index}
        />
      ))}
    </>
  );
}

export { GallerySkeleton, CategoryChipsSkeletion };
