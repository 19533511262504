import {
  galleryInterface,
  PhotosInterface,
} from "../../interfaces/gallery.interface";
import * as galleryActionTypes from "../actions/GalleyActions/galleryActionTypes";

const initState: galleryInterface = {
  photos: [],
  uploadPhotos: [],
  isFeaturedImg: false,
  photosLoading: false,
  uploadLoading: false,
  totalPhotos: 0,
  totalPages: 1,
  currentPage: 1,
  addVideoLoader: false,
  videos: [],
  videosLoading: false,
  totalVideos: 0,
  totalVideoPages: 1,
  videoCurrentPage: 1,
  editingVideos: false,
  deletingVideos: false,
};

export const galleryReducer = (
  state = initState,
  action: any
): galleryInterface => {
  switch (action.type) {
    case galleryActionTypes.PHOTOS_LOADING:
      return {
        ...state,
        photosLoading: true,
      };
    case galleryActionTypes.PHOTOS_LOADING_SUCCESS:
      return {
        ...state,
        photos:
          action.currentPage === 1
            ? action.payload.photos
            : state.photos.concat(action.payload.photos),
        totalPhotos: action.payload.totalPhotos,
        totalPages: action.payload.totalPages,
        currentPage: action.currentPage > 1 ? state.currentPage + 1 : 2,
        photosLoading: false,
      };
    case galleryActionTypes.PHOTOS_LOADING_ERROR:
      return {
        ...state,
        photosLoading: false,
      };

    case galleryActionTypes.UPLOAD_PHOTOS_LOADING:
      return {
        ...state,
        uploadLoading: true,
      };
    case galleryActionTypes.UPLOAD_PHOTOS_LOADING_SUCCESS:
      return {
        ...state,
        uploadLoading: false,
      };
    case galleryActionTypes.UPLOAD_PHOTOS_LOADING_ERROR:
      return {
        ...state,
        uploadLoading: false,
      };

    case galleryActionTypes.ADDING_VIDEOS:
      return {
        ...state,
        addVideoLoader: true,
      };
    case galleryActionTypes.ADDING_VIDEOS_SUCCESS:
      return {
        ...state,
        addVideoLoader: false,
      };
    case galleryActionTypes.ADDING_VIDEOS_ERROR:
      return {
        ...state,
        addVideoLoader: false,
      };

    case galleryActionTypes.VIDEOS_LOADING:
      return {
        ...state,
        videosLoading: true,
      };
    case galleryActionTypes.VIDEOS_LOADING_SUCCESS:
      return {
        ...state,
        videos: [...action.payload],
        videoCurrentPage: action.currentPage,
        totalVideoPages: action.totalVideos,
        videosLoading: false,
      };
    case galleryActionTypes.VIDEOS_LOADING_ERROR:
      return {
        ...state,
        videosLoading: false,
      };
    case galleryActionTypes.EDITING_VIDEOS:
      return {
        ...state,
        editingVideos: true,
      };
    case galleryActionTypes.EDITING_VIDEOS_SUCCESS:
      return {
        ...state,
        editingVideos: false,
      };
    case galleryActionTypes.EDITING_VIDEOS_ERROR:
      return {
        ...state,
        editingVideos: false,
      };
    case galleryActionTypes.DELETING_VIDEOS:
      return {
        ...state,
        deletingVideos: true,
      };
    case galleryActionTypes.DELETING_VIDEOS_SUCCESS:
      return {
        ...state,
        deletingVideos: false,
      };
    case galleryActionTypes.DELETING_VIDEOS_ERROR:
      return {
        ...state,
        deletingVideos: false,
      };
    case galleryActionTypes.UPDATING_PHOTOS_SUCCESS: {
      const index = state.photos.findIndex(
        (photo: PhotosInterface) => photo._id === action.id
      );
      if (index > -1) {
        let newPhotosList = [...state.photos];
        newPhotosList[index] = {
          _id: action.id,
          category: action.payload.category,
          isFeatured: action.payload.featured,
          url: action.payload.url,
        };
        return {
          ...state,
          photos: [...newPhotosList],
        };
      }
      return state;
    }

    case galleryActionTypes.DELETING_PHOTOS_SUCCESS:
      // const index = state.photos.findIndex(
      //   (photo: PhotosInterface) => photo._id === action.id
      // );
      // if (index > -1) {
      //   let newPhotosList = [...state.photos];
      //   newPhotosList.splice(index, 1);
      //   return {
      //     ...state,
      //     photos: [...newPhotosList],
      //   };
      // }
      return state;
    default:
      return state;
  }
};
