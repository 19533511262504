import { Breadcrumb, Form, Input, Button, Upload, message, Switch, Row, Col } from "antd";
import { HomeOutlined, TableOutlined, PlusOutlined, } from "@ant-design/icons";
import { buildFeaturedVideoRequestBody, buildVideoRequestBody } from "../../../../../interfaces/gallery.interface";
import { useDispatch, useSelector } from "react-redux";
import { addVideo, createFeaturedVideo, getFeaturedVideo } from "../../../../../store/actions/GalleyActions/galleryActionCreators";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const AddVideos = () => {
  const [form] = Form.useForm();
  const { addVideoLoader }: any = useSelector<any>(
    (state) => state.galleryReducer
  );
  const [featuredForm, setFeaturedForm] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [feauredVideos, setFeauredVideos] = useState<any>({})
  const dispatch = useDispatch();

  const onFinish = (values: any) => {
    const data: FormData = buildVideoRequestBody(values);
    dispatch(
      addVideo(data, (res: any, err: any) => {
        if (err) {
          console.log(err);
        }
        if (res) {
          message.success("Videos added successfully");
          form.resetFields();
        }
      })
    );
  };

  const onFinishFeaturedVideo = (values: any) => {
    const data: FormData = buildFeaturedVideoRequestBody(values);
    setLoading(true)
    dispatch(
      createFeaturedVideo(data, (res: any, err: any) => {
        setLoading(false)
        if (err) {
          console.log(err);
        }
        if (res) {
          message.success("Featured Videos added successfully");
          form.resetFields();
          getFeature()
        }
      })
    );
  };

  const urlValidator = (rule: any, value: any) => {
    return new Promise((resolve: any, reject: any) => {
      if (value.length === 0) {
        reject("Video url is required");
      }
      if (
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w-]+)+[\w\-_~:/?#[\]@!&',;=.]+$/.test(
          value
        )
      ) {
        resolve();
      }
      reject("Link is not in correct format");
    });
  };

  const handleFeaturedForm = (e: any) => {
    if (e) {
      setFeaturedForm(false)
    } else {
      setFeaturedForm(true)
    }
  }

  const getFeature = () =>{
    dispatch(
      getFeaturedVideo((res: any, err: any) => {
        if (err) {
          console.log(err);
        }
        if (res) {
          setFeauredVideos(res)
        }
      })
    );
  }

  useEffect(() => {
    getFeature()

  }, [])

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div className="main-contents">
      <Breadcrumb>
        <Breadcrumb.Item href="/home">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Link to="/gallery/videos">
          <Breadcrumb.Item href="">
            <TableOutlined />
            <span>Video Collection</span>
          </Breadcrumb.Item>
        </Link>
        <Breadcrumb.Item className="active">Add Videos</Breadcrumb.Item>
      </Breadcrumb>
      <div className="content-title">
        <h3>Videos</h3>
        <div className="d-flex">
          <p>Upload videos</p>
          <div className="featured_video ml-auto">
            <Switch checkedChildren="Featured" unCheckedChildren="Not Featured" onChange={handleFeaturedForm} />
            {/* <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked /> */}
          </div>
        </div>
      </div>
      <div className="content-add-form">
        <div className="form-wraps">

          {featuredForm ?
            <Form form={form} name="control-hooks" onFinish={onFinish} layout="vertical">
              <Form.Item
                name="title"
                label="Title"
                rules={[
                  { required: true, message: "Title is required" },
                  {
                    min: 4,
                    message: "Title must be of atleast 4 character long",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="url"
                label="Url"
                rules={[{ required: true }, { validator: urlValidator }]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="thumbnailImg" label="Thumbnail Image">
                <Upload
                  maxCount={1}
                  listType="picture-card"
                  beforeUpload={() => false}
                  multiple
                  showUploadList={{ showPreviewIcon: false }}
                >
                  {uploadButton}
                </Upload>
              </Form.Item>
              <Form.Item>
                <Button loading={addVideoLoader} type="primary" htmlType="submit">
                  Submit
              </Button>
              </Form.Item>
            </Form>
            :
            <Row gutter={20}>
              <Col span={10}>
                <Form form={form} name="control-hooks" onFinish={onFinishFeaturedVideo} layout="vertical">
                  <Form.Item
                    name="title"
                    label="Video Title"
                    rules={[
                      { required: true, message: "Title is required" },
                      {
                        min: 4,
                        message: "Title must be of atleast 4 character long",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item name="thumbnailImg" label="Thumbnail Image">
                    <Upload
                      maxCount={1}
                      listType="picture-card"
                      beforeUpload={() => false}
                      multiple
                      showUploadList={{ showPreviewIcon: false }}
                    >
                      {uploadButton}
                    </Upload>
                  </Form.Item>
                  <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                      Submit
              </Button>
                  </Form.Item>
                </Form>

              </Col>
              <Col span={14}>
                <div className="featured-video-wrap ml-auto">
                  <h3>Featured Video</h3>
                  <div className="featured-video">
                    <video controls>
                      <source src={feauredVideos?.featuredVideo} type="video/mp4" />
                    </video>
                  </div>

                </div>

              </Col>
            </Row>

          }
        </div>
      </div>
    </div>
  );
}

export default AddVideos;
