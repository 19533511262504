import * as authActionTypes from "./authActionTypes";
import axios from "../../../api/axios";

export const authenticate = (data: any, cb: Function) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: authActionTypes.LOGIN_START });
      const response = await axios.post("/auth", data);
      if (response.status === 200) {
        dispatch({
          type: authActionTypes.LOGIN_SUCCESS,
          token: response.data.token,
        });
        localStorage.setItem("token", response.data.token);
        cb(true, null);
      } else {
        dispatch({ type: authActionTypes.LOGIN_ERROR });
        cb(null, true);
      }
    } catch (err) {
      dispatch({ type: authActionTypes.LOGIN_ERROR });
      cb(null, err);
    }
  };
};

export const checkAuth = () => {
  return (dispatch: any) => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch({
        type: authActionTypes.LOGIN_SUCCESS,
        token: token,
      });
      return true;
    } else {
      return false;
    }
  };
};

export const logout = (cb: Function) => (dispatch: any) => {
  localStorage.removeItem("token");
  dispatch({
    type: authActionTypes.LOGOUT,
  });
  cb();
};
