export const PHOTOS_LOADING = "PHOTOS_LOADING";
export const PHOTOS_LOADING_SUCCESS = "PHOTOS_LOADING_SUCCESS";
export const PHOTOS_LOADING_ERROR = "PHOTOS_LOADING_ERROR";

export const UPLOAD_PHOTOS_LOADING = "UPLOAD_PHOTOS_LOADING";
export const UPLOAD_PHOTOS_LOADING_SUCCESS = "UPLOAD_PHOTOS_LOADING_SUCCESS";
export const UPLOAD_PHOTOS_LOADING_ERROR = "UPLOAD_PHOTOS_LOADING_ERROR";


export const DELETING_PHOTOS_SUCCESS = "DELETING_PHOTOS_SUCCESS";
export const UPDATING_PHOTOS_SUCCESS = "UPDATING_PHOTOS_SUCCESS";

export const VIDEOS_LOADING = "VIDEOS_LOADING";
export const VIDEOS_LOADING_SUCCESS = "VIDEOS_LOADING_SUCCESS";
export const VIDEOS_LOADING_ERROR = "VIDEOS_LOADING_ERROR";

export const ADDING_VIDEOS = "ADDING_VIDEOS";
export const ADDING_VIDEOS_SUCCESS = "ADDING_VIDEOS_SUCCESS";
export const ADDING_VIDEOS_ERROR = "ADDING_VIDEOS_ERROR";


export const EDITING_VIDEOS = "EDITING_VIDEOS";
export const EDITING_VIDEOS_SUCCESS = "EDITING_VIDEOS_SUCCESS";
export const EDITING_VIDEOS_ERROR = "EDITING_VIDEOS_ERROR";


export const DELETING_VIDEOS = "DELETING_VIDEOS";
export const DELETING_VIDEOS_SUCCESS = "DELETING_VIDEOS_SUCCESS";
export const DELETING_VIDEOS_ERROR = "DELETING_VIDEOS_ERROR";


export const FEATURED_VIDEO = "FEATURED_VIDEO";
export const FEATURED_VIDEO_SUCCESS = "FEATURED_VIDEO_SUCCESS";
export const FEATURED_VIDEO_ERROR = "FEATURED_VIDEO_ERROR";


export const GET_FEATURED_VIDEO = "GET_FEATURED_VIDEO";
export const GET_FEATURED_VIDEO_SUCCESS = "GET_FEATURED_VIDEO_SUCCESS";
export const GET_FEATURED_VIDEO_ERROR = "GET_FEATURED_VIDEO_ERROR";
