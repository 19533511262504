import { message, Modal, Typography } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { IHttpError } from "../../../../../interfaces/http.interface";
import {
  deletePhoto,
  getAllPhotos,
} from "../../../../../store/actions/GalleyActions/galleryActionCreators";

interface IProps {
  id: Array<string>;
  show: boolean;
  closeModal: () => void;
}

function DeletePhotos({ id, show, closeModal }: IProps) {
  const [deletingImage, setDeletingImage] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { title }: any = useParams();

  const deleteImageHandler = () => {
    setDeletingImage(true);
    dispatch(
      deletePhoto(id, (res: boolean, err: IHttpError | null) => {
        setDeletingImage(false);
        if (res) {
          setDeletingImage(false);
          message.success("Image deleted successfully");
          closeModal();
          dispatch(
            getAllPhotos(1, title, (res: any, err: any) => {
              if (err) {
                console.log(err);
              }
            })
          );
        }
        if (err) {
          message.error(
            err.response?.data?.message || "Could not delete at the moment"
          );
        }
      })
    );
  };

  return (
    <Modal
      visible={show}
      title="Delete Image"
      okText="Delete"
      onOk={deleteImageHandler}
      onCancel={closeModal}
      confirmLoading={deletingImage}
    >
      <Typography.Text>
        Are you sure you want to delete {id.length === 1 ? "this" : id.length}{" "}
        image?
      </Typography.Text>
    </Modal>
  );
}

export default DeletePhotos;
