export interface galleryInterface {
  photos: Array<PhotosInterface>;
  uploadPhotos: Array<any>;
  isFeaturedImg: boolean;
  uploadLoading: boolean;
  photosLoading: boolean;
  totalPhotos: number;
  totalPages: number;
  currentPage: number;
  videos:any;
  addVideoLoader: boolean;
  videosLoading:boolean;
  totalVideos:number;
  totalVideoPages:number,
  videoCurrentPage:number,
  editingVideos: boolean,
  deletingVideos:boolean,
}

export interface PhotosListInterface {
  photos: Array<PhotosInterface>;
}

export interface PhotosInterface {
  _id: string;
  isFeatured: boolean;
  url: string;
  createdAt?: string;
  category: Array<any>;
}

export const buildVideoList = (videos: Array<any>, currentPage: number) => {
  let videoList = videos.map((video, index) => ({
    ...video,
    key: (currentPage - 1) * 10 + index + 1,
  }));
  return videoList;
};

export const buildPhotosRequestBody = (photos: any) => {
  const formData = new FormData();
  formData.append("isFeatured", photos.featured);
  photos.selectedCategory.forEach((catgoryId: any) => {
    formData.append("categoryIds", catgoryId);
  });
  photos.files.forEach((file: any) =>
    formData.append("galleryImg", file.originFileObj)
  );
  return formData;
};

export const buildVideoRequestBody = (values: any): FormData => {
  const formData: FormData = new FormData();
  formData.append("url", values.url);
  formData.append("title", values.title);
  values.thumbnailImg &&
    formData.append("thumnailImg", values.thumbnailImg.file);
  return formData;
};

export const buildFeaturedVideoRequestBody = (values: any): FormData => {
  const formData: FormData = new FormData();
  formData.append("isFeatured", "true");
  formData.append("title", values.title);
  values.thumbnailImg &&
    formData.append("featuredVideo", values.thumbnailImg.file);
  return formData;
};



export const buildUpdateVideoRequestBody = (data: any): FormData => {
  console.log(data, "asdasd")
  const formData: FormData = new FormData();
  formData.append("url", data.url);
  formData.append("title", data.title);
  data.file &&
    data.file[0] &&
    data.file[0] &&
    formData.append("thumnailImg", data.file[0].originFileObj);
  return formData;
};

export const buildUpdatePhotosRequestBody = (data: any): FormData => {
  const formData: FormData = new FormData();
  formData.append("isFeatured", data.featured);
  data.category &&
    data.category.length > 0 &&
    data.category.forEach((category: string) => {
      formData.append("categoryIds", category);
    });
  data.file &&
    data.file[0] &&
    data.file[0] &&
    data.file[0].originFileObj &&
    formData.append("galleryImg", data.file[0].originFileObj);
  return formData;
};
