export const LOADING_CATEGORY = "LOADING_CATEGORY";
export const LOADING_CATEGORY_SUCCESS = "LOADING_CATEGORY_SUCCESS";
export const LOADING_CATEGORY_ERROR = "LOADING_CATEGORY_ERROR";

export const ADDING_CATEGORY = "ADDING_CATEGORY";
export const ADDING_CATEGORY_SUCCESS = "ADDING_CATEGORY_SUCCESS";
export const ADDING_CATEGORY_ERROR = "ADDING_CATEGORY_ERROR";

export const EDITING_CATEGORY = "EDITING_CATEGORY";
export const EDITING_CATEGORY_SUCCESS = "EDITING_CATEGORY_SUCCESS";
export const EDITING_CATEGORY_ERROR = "EDITING_CATEGORY_ERROR";

export const DELETING_CATEGORY = "DELETING_CATEGORY";
export const DELETING_CATEGORY_SUCCESS = "DELETING_CATEGORY_SUCCESS";
export const DELETING_CATEGORY_ERROR = "DELETING_CATEGORY_ERROR";

export const GETTING_CATEGORY_BY_TITLE = "GETTING_CATEGORY_BY_TITLE";
export const GETTING_CATEGORY_BY_TITLE_SUCCESS =
  "GETTING_CATEGORY_BY_TITLE_SUCCESS";
export const GETTING_CATEGORY_BY_TITLE_ERROR =
  "GETTING_CATEGORY_BY_TITLE_ERROR";
